const FIELDS_AND_INPUTS = {
  PRODUCT_PERIOD: 'Срок продукта',
  PRODUCT_START: 'Начало действия договора',
  INVEST_DATE: 'Дата инвестирования',
  POLICY_SUM: 'Сумма договора, ',
  INSURANCE_PREMIUM: 'Страховая премия',
  CONTRACT_CURRENCY: 'Валюта договора',
  COUPON_MAIN_STRATEGY: 'Купон по основной стратегии (в % годовых)',
  PARTICIPATION_COEFFICIENT_MAIN_STRATEGY: 'Коэффициент участия по основной стратегии',
  COUPON_INFO: 'Итоговый параметр доходности по корзине зависит от указываемого размера страхового взноса',
  RATE: 'Курс валюты',
  INSURER_DATA: 'Данные страхователя',
  INSURED_DATA: 'Данные застрахованного',
  CONTRACT_DATA: 'Данные договора',
  CLIENT_DATA: 'Данные клиента',
  DOCUMENTS: 'Документ, удостоверяющий личность',
  REGISTRATION_ADDRESS: 'Адрес регистрации',
  LIVING_ADDRESS: 'Адрес проживания',
  CONTACT_DATA: 'Контактные данные',
  BANK_DETAILS: 'Банковские реквизиты',
  LAST_NAME: 'Фамилия',
  FIRST_NAME: 'Имя',
  PATRONYMIC: 'Отчество',
  GENDER_SHORT: 'Пол',
  BIRTH_DATE_SHORT: 'Дата рождения',
  BIRTH_PLACE: 'Место рождения',
  INDEX: 'Индекс',
  REPUBLIC: 'Республика/край/обл',
  AREA: 'Район',
  CITY: 'Город / нас.пункт',
  STREET: 'Улица',
  HOUSE: 'Дом',
  HOUSE_NUMBER: 'Корп',
  BUILD: 'Стр.',
  APARTMENT: 'Квартира',
  BIC: 'БИК',
  BANK_NAME: 'Наименование банка',
  INN: 'ИНН',
  KPP: 'КПП',
  COR_ACCOUNT_SHORT: 'к/с',
  CHECK_ACCOUNT_SHORT: 'р/с',
  RELATIONSHIP_PERSON_WITH_AGENT: 'Родственное отношение застрахованного к страхователю',
  RELATIONSHIP_PERSON_WITH_AGENT_CHILD: 'Родственное отношение застрахованного ребенка к страхователю',
  INSURED_DATA_CHILD: 'Данные застрахованного ребенка',
  DOCUMENTS_CHILD: 'Документ, удостоверяющий личность ребенка',
  REGISTRATION_ADDRESS_CHILD: 'Адрес регистрации ребенка',
  LIVING_ADDRESS_CHILD: 'Адрес проживания ребенка',
  CONTACT_DATA_CHILD: 'Контактные данные ребенка',
  API_KEY: 'API ключ',
  COMMENT: 'Комментарий',
  AVAILABLE_API: 'Доступные API',
  SECRET_KEY_UPDATE_DATE: 'Дата обновление секретного ключа',
  ID_ADDRESS: 'IP-адрес',
  CREATE_API_KEY: 'Создать API ключ',
  NO_RESTRICTION: 'Без ограничений',
  DESCRIPTION_DATE_INVEST: 'Дата покупки Страховщиком базовых активов, от которой производится расчет следующих дат наблюдения по программе',
  USE_FOR_SEARCH: 'Использовать для поиска',
  KSZ_TERM_ERROR: (from, to) => `Введите или выберите значение от ${from} до ${to}`,
};

const CURRENCY_LIST = {
  RUB_SHORT: 'руб.',
};

const DOCUMENT_STATUS = {
  NEW_DOCUMENT: 'Новые',
  CHECKED_DOCUMENT: 'Проверенные',
  PARTIALLY_CHECKED_DOCUMENT: 'Частично проверенные',
  STATUS_CHECK: 'Статус проверки',
  DATE_AND_TIME_CHECK: 'Дата и время проверки',
  DATE_CHECK: 'Дата проверки',
  CHECKING_DOCUMENTS: 'Проверка документов',
  CHECKING_DOCUMENT: 'Проверка документа',
  DESCRIPTION_INFO_BLOCK_UNDERWRITING: 'Выберите, используя фильтры, договоры для проверки и нажмите на кнопку "Далее"',
  ISSUED_BY_BUTTON_ACCEPT: 'Подтвердить',
  ISSUED_BY_BUTTON_ACCEPTED: 'Подтверждено!',
  ISSUED_BY_CHANGE_NOTIFICATION: 'ВАЖНО! НИКАКИЕ СОКРАЩЕНИЯ В ПАСПОРТНЫХ ДАННЫХ НЕ ДОПУСКАЮТСЯ!\nПроверьте заполненные данные на ПОЛНОЕ совпадение с данными в паспорте.\nПри необходимости – исправьте данные вручную и нажмите кнопку “Подтвердить”.',
};

const IMPORT_FORM = {
  TITLE_IMPORT: 'Импорт',
  TIME_TASK_START: 'Время запуска задания',
  STATUS_NEXT_TASK: 'Статус следующего задания',
  REPEAT_DAY: 'Ежедневный повтор',
  STATUS_PREV_TASK: 'Статус предыдущего задания',
  SCHEDULED: 'Запланировано',
  SUCCESS_TASK: 'Выполнено успешно',
  FAIL_TASK: 'Завершилось с ошибкой',
  NOT_SCHEDULED: 'Не запланировано',
  NO_TASK: 'Задания отсутствуют',
};

const ERROR_VALIDATION = {
  NO_MORE_NINE: 'БИК не должен превышать 9 цифр',
  MUST_BE_NINE: 'Необходимо ввести 9 цифр',
  MUST_BE_TEN: 'Необходимо ввести 10 цифр',
  MUST_BE_TWENTY: 'Необходимо ввести 20 цифр',
  DATE_INVALID: 'Некорректная дата',
  CHECK_SUM_CUR_ACCOUNT: 'Неправильное контрольное число',
  INVALID_CODE_CURRENCY: 'Неправильный код валюты',
  UNKNOWN_ERROR: 'Неизвестная ошибка',
  MAX_HUNDRED: 'Максимально 100 символов',
  PERSON_MIN_AGE_ERROR: (age) => `Лицу менее ${age} лет`,
  PERSON_MAX_AGE_ERROR: (age) => `Лицу более ${age} лет`,
  PERSON_MAX_AGE_ERROR_MONTHS: 'месяцев',
  PERSON_MAX_AGE_ERROR_MONTH: 'месяцa',
  INSURED_IS_BENEFICIARY_ERROR: 'Застрахованное лицо не может быть выгодоприобретателем',
  INSURED_AGE_ERROR: 'Застрахованный не соответсвует возрастным ограничениям',
  EMAIL_VALIDATION: 'Неправильный E-mail',
  ERROR_UPDATE_BASKETS: 'Ошибка при обновлении данных о корзинах',
  ISSUED_BY_ACCEPT_REQUIRED: 'Вы не подтвердили полное совпадение заполненных данных с данными в паспорте',
  TEMP_CERT_VALID_UNTIL_DATE: 'Временное удостоверение не действительно, дальнейшее оформление невозможно',
  INCORRECT_PRODUCT_SETTINGS: 'Некорректные настройки продукта',
};

const ADMINISTRATION = {
  TITLE_EXPORT: 'Экспорт',
  DESCRIPTION_1C: 'Интерфейс экспорта данных в 1С Ортикон УСК',
  SETTING_EXPORT: 'Настройка экспорта',
  PATCH_EXPORT: 'Путь выгрузки файлов экспорта',
  SELECT_PARTNER_ADMIN: 'Выберите всех или определенного партнера',
  SELECT_PERIOD_ADMIN: 'Выберите период, за который были сохранены договоры',
  SELECT_SIGN_PERIOD_ADMIN: 'Выберите период, за который были подписаны договоры',
  SELECT_STATUS_ADMIN: 'Выберите статусы или определенный статус договоров',
  REWRITE_FILES: 'Перезаписать ранее выгруженные файлы',
  BEGIN_UNLOADING: 'Произведите выгрузку',
  EXPORT_SUCCESS: 'Успешно',
  PROCESS_EXPORT: 'Производится выгрузка',
  NUMBER_CONTRACT_UPLOAD: 'Номер контракта к выгрузке',
};

const DESCRIPTION_ADMIN = {
  IMPORT_ADMIN: 'Импорт',
  TITLE_ADMIN: 'Интерфейс импорта данных:',
  PARTNER_ADMIN: '-партнера',
  BRANCHES_ADMIN: '-подразделений',
  USERS_ADMIN: '-пользователей',
  SETTINGS_ADMIN: '-настроек продуктов',
  LIST_PODFT_ADMIN: '-списков ПОД/ФТ',
  PARTNERS_AND_PRODUCTS: 'Партнеры и продукты',
  PODFT_TITLE: 'ПОД/ФТ',
  UPLOAD_PARTNER: 'Загрузить партнера',
  UPLOAD_BRANCHES: 'Загрузить подразделения',
  UPLOAD_USERS: 'Загрузить пользователей',
  UPLOAD_TARIFF_NSZ: 'Загрузка тарифов НСЖ',
  UPLOAD_SETTINGS: 'Загрузить настройки',
  PLACEHOLDER_FILE_UPLOAD: 'Прикрепить файл',
  UPLOAD_LIST_TERRORIST: 'Загрузить список экстремистов',
  UPLOAD_LIST_OON: 'Загрузить список экстремистов ООН',
  UPLOAD_LIST_MBK: 'Загрузить список МВК',
  UPLOAD_LIST_FROMU: 'Загрузить список ФРОМУ',
  UPDATE_LIST_BAD_ADRESS: 'Загрузить список ограничений суммы для адресов',
  UPDATE_LIST_INVALID_PASSPORT: 'Загрузить список недействительных паспортов',
  UPLOAD_PROCESS: 'Импорт файла',
  UPLOAD_SHORT: 'Загрузить',
  UPLOAD_LAST_DATE: 'Дата обновления ',
  UPLOAD_LAST_VERSION: 'Версия обновления ',
};

const PAYMENT_FIELDS = {
  DEATH_ANY_REASON: 'Дожитие, смерть застрахованного по любой причине',
  DEATH_ACCIDENT: 'Смерть в результате несчастного случая',
  INJURY_ACCIDENT: 'Травма в результате несчастного случая',
  HOSPITALIZATION_ACCIDENT: 'Госпитализация в результате несчастного случая',
  DISABILITY_OUSV: 'Инвалидность I, II группа (любая причина) с ОУСВ',
  DISABILITY_ANY_REASON: 'Инвалидность I, II и III группа (любая причина)',
  CRITICAL_DISEASES: 'Кртические заболевания',
  INSURANCE_RISK: 'Страховой риск',
  INSURANCE_AMOUNT_TITLE: 'Страховая сумма (заполняется)',
  INSURANCE_FEE: 'Страховой взнос (величина регулярного платежа)',
};

const DOCS_TRANSFER = {
  DOCS_TRANSFER: 'Документооборот',
  DATE: 'Дата',
  ORGANISATION: 'Организация',
  HAVE_READ: 'Прочитано',
  HAVE_RECEIVED: 'Получено',
  SENDER_FIO: 'ФИО Отправителя',
  SENDER_PARTNER: 'Организация-отправитель',
  RECIPIENT_FIO: 'ФИО Получателя',
  RECIPIENT_PARTNER: 'Организация-получатель',
  RECIPIENT: 'Получатель',
  RECIPIENT_ORGANISATION: 'Организация получателя',
  CREATE_MESSAGE_SUCCESS: 'Сообщение успешно отправлено',
  DOCS_TRANSFER_MAX_FILES_ERROR: 'Можно загрузить не более 20-ти файлов',
  DOCS_TRANSFER_MARK_AS_READ_SUCCESS: 'Сообщение помечено прочитанным',
};

const LANG_DICTIONARY = {
  ...DOCUMENT_STATUS,
  ...PAYMENT_FIELDS,
  ...DESCRIPTION_ADMIN,
  ...FIELDS_AND_INPUTS,
  ...CURRENCY_LIST,
  ...ERROR_VALIDATION,
  ...IMPORT_FORM,
  ...ADMINISTRATION,
  ...DOCS_TRANSFER,
  CANT_DELETE_ONE_FIELD: 'Нельзя удалить единственное поле',
  ENTER_FIRSTNAME: 'Введите имя',
  ENTER_LASTNAME: 'Введите фамилию',
  ENTER_MIDDLENAME: 'Введите отчество',
  NOT_CHOOSED: 'Не выбрано',
  DATE_LAST_LOGIN: 'Дата последнего входа',
  LAST_IP_ADDRES: 'Последний IP-адрес',
  LOCKED: 'Заблокирован',
  LOCK: 'Заблокировать',
  UNLOCK: 'Разблокировать',
  FILTER: 'Фильтр',
  LOGIN: 'Логин',
  STATUSUSER: 'statusUser',
  STATUSSITE: 'statusSite',
  LOGINFIO: 'Логин/ФИО',
  DEFAULT_SUBDIVISION: 'Дефолтное подразделение',
  CURRENT_TIME: 'Текущее время:',
  DIGITAL_PLATFORM: 'Цифровая платформа',
  IP_ADDRESS: 'IP адрес:',
  WELCOME: 'Добро пожаловать!',
  USER_NAME: 'Имя пользователя',
  USER_CREATED: 'Пользователь создан',
  USER_UPDATED: 'Пользователь обновлен',
  USER_LOCKED: 'Пользователь забанен',
  USER_UNLOCKED: 'Пользователь разбанен',
  PASSWORD: 'Пароль',
  NEW_PASSWORD: 'Новый пароль',
  CONFIRM_NEW_PASSWORD: 'Подтвердите пароль',
  PASSWORDS_NOT_A_SAME: 'Пароли не совпадают',
  CHANGE_PASSWORD: 'Смена временного пароля',
  SET_NEW_PASSWORD: 'Задайте новый пароль',
  SIGN_IN: 'Войти',
  OR: 'или',
  DOWNLOADING: 'Загрузка',
  REMOTE_ACCESS: 'восстановить доступ',
  EMAIL: 'Email',
  CHECKER: 'Проверяющий',
  REMOTE_BUTTON: 'Восстановить',
  REQUIRED: 'Поле обязательно',
  NO_MORE_THEN_FIFTY: 'Поле должно занимать не более 50 символов',
  NO_MORE_THEN_THREE_HUNDRED: 'Поле должно занимать не более 100 символов',
  NO_MORE_TWENTY: 'Поле должно занимать не более 25 символов',
  NO_MORE_TWO_HUNDRED_FIFTY_FIVE: 'Поле должно занимать не более 255 символов',
  NO_MORE_TEN: 'Поле должно занимать не более 10 символов',
  LOGIN_VALIDATION: 'Введите имя пользователя или email',
  AT_LEAST_SIX: 'Минимальная длина пароля - 6 символов',
  AT_LEAST_EIGHT: 'Минимальная длина пароля - 8 символов',
  RESET_PASSWORD: 'Восстановление пароля',
  ENTER_NEW_PASSWORD: 'Введите новый пароль',
  IS_NUMBER: 'Поле должно содержать число',
  IS_DATE: 'Поле должно содержать дату',
  CHECK_EMAIL: 'Письмо с инструкцией по смене пароля было отправльнено на указанную почту',
  ENTER_YOUR_EMAIL: 'Введите почту',
  INVALID_EMAIL_MESSAGE: 'Некорректный email',
  PASSWORD_RESET: 'Сброс пароля',
  PASSWORD_RESET_INSTRUCTION: 'Введите почту, указанную при регистрации. На нее придет код подтверждения',
  EMAIL_REGISTRATION_ERROR: 'При регистрации была указана другая почта. Проверьте правильность написания или обратитесь по адресу ',
  ACCOUNT_BLOCKED_ERROR: 'Учетная запись заблокирована. Проверьте правильность написания почты или обратитесь по адресу ',
  SEND_REQUEST: 'Отправить запрос',
  CHECK_YOUR_EMAIL: 'Проверьте вашу почту',
  EMAIL_WITH_CODE_SEND: 'Вам направлено письмо с кодом восстановления',
  ENTER_CONFIRMTION_CODE_SENT_TO_EMAIL: 'Введите код подтверждения, направленный вам на указанную почту',
  ENTER_CONFIRMTION_CODE: 'Введите код подтверждения',
  GET_CODE_AGAIN: 'Получить код повторно',
  GET_CODE_AGAIN_VIA: 'Получить код повторно через ',
  INVALID_CODE_ERROR: (remainingAttempts) => `Введен неверный код, осталось ${remainingAttempts} попытки`,
  CONTACT_SUPPORT: 'Обратитесь в поддержку',
  INVALID_CODE_ATTEMPTS: 'Вы ввели неверный код подтверждения 5 раз',
  INVALID_CODE_LENGTH: 'Код должен содержать 6 символов',
  PASSWORD_MIN_LENGTH: 'Длина не менее 8 символов',
  PASSWORD_MUST_CONTAIN_NUMBER: 'Наличие хотя бы одной цифры',
  PASSWORD_MUST_CONTAIN_LOWERCASE: 'Наличие хотя бы одной буквы в нижнем регистре',
  PASSWORD_MUST_CONTAIN_UPPERCASE: 'Наличие хотя бы одной буквы в верхнем регистре',
  PASSWORD_MUST_CONTAIN_SPECIAL_CHAR: 'Наличие хотя бы одного специального символа',
  WEAK_PASSWORD_WARNING: 'Пароль слишком распространён и легко подбирается',
  PASSWORDS_MATCH: 'Пароли совпадают',
  PASSWORD_CHANGE_SUCCESS: 'Пароль успешно изменен',
  GO_TO_LOGIN_FORM: 'Перейти к форме входа',
  YIELDS: 'Доходность (ож.) годовых',
  PERIOD: 'Срок',
  ROLE: 'Роль',
  CONTRIBUTION: 'Взнос, тыс.руб.',
  CHECKOUT: 'Оформить >',
  DOWNLOAD: 'Загрузка',
  USER_MANUAL: 'Руководство пользователя',
  SIGN_OUT: 'Выход',
  USER_INFO: 'паспорт, инн, ФИО',
  PARTNER: 'Партнер',
  PARTNER_ID: 'ID партнера',
  SUBDIVISION: 'Подразделение',
  USER: 'Пользователь',
  BACK: '< На главную',
  NEXT: 'Далее >',
  CONTRACT_START: 'Начало действия контракта',
  GENDER: 'Пол застрахованного',
  BIRTH_DATE: 'Дата рождения застрахованного',
  PASSPORT: 'Паспорт',
  SERIAL: 'Серия',
  NUMBER: 'Номер',
  ISSUED_BY: 'Кем выдан',
  ISSUED_BY_SHORT: 'Выдан',
  WHEN: 'Когда',
  KP: 'Код подразделениия',
  VALID_UNTIL: 'Действительно до',
  ERROR: 'Ошибка',
  KP_TOGETHER: 'КП',
  REGISTRATION_ADDRESS_FACT: 'Адрес фактический',
  SELECT_ADDRESS: 'Подобрать адрес',
  SAME_ADDRESS: 'Совпадает с адресом регистрации',
  SNILS: 'СНИЛС',
  MOBILE_PHONE: 'Мобильный телефон',
  HOME_PHONE: 'Домашний телефон',
  WORK_PHONE: 'Рабочий телефон',
  CONTACT_PHONE: 'Контактный телефон',
  SEARCH_ADDRESS: 'Введите адрес для поиска',
  IS_SAME: 'Застрахованным является страхователь',
  BENEFICIARY_BY_LAW: 'Выгодоприобретатель по закону',
  BENEFICIARY_COUNT: 'Число выгодоприобретателей',
  DATA: 'Данные',
  DATA_OF_BENEFICIARY: 'выгодоприобретателя',
  RELATIONSHIPS: 'Родственные отношения',
  INSURED_SUM: 'Доля от страховой суммы, %',
  DOCUMENTS_SHORT: 'Документы',
  ADDRESS_SHORT: 'Адрес',
  CONTACTS_SHORT: 'Контакты',
  MUST_BE_ONE_HUNDRED: 'Сумма долей должна быть 100%',
  WITHOUT_DOCUMENTS: 'Без документов',
  WITHOUT_ADDRESS: 'Без адреса',
  WITHOUT_CONTACTS: 'Без контактов',
  ONLY_CYRILLIC: 'Разрешена только кириллица',
  ONLY_CYRILLIC_SYMBOLS: 'Разрешена только кириллица и символы (, . -)',
  ONLY_CYRILLIC_SYMBOLS_WITH_NUMBERS: 'Разрешена только кириллица, цифры и символы (, . - /)',
  ONLY_CYRILLIC_LATIN_SYMBOLS_QUOTES: 'Разрешена кириллица, латиница, цифры и символы (" \' / - #)',
  ONLY_CYRILLIC_DASH: 'Разрешена только кириллица и тире',
  ONLY_CYRILLIC_DASH_AND_BRACKETS: 'Разрешена только кириллица, тире и скобки',
  ONLY_CYRILLIC_LATIN_NUMBERS: 'Разрешена только кириллица, латиница и цифры',
  ONLY_CYRILLIC_DASH_AND_SPACES: 'Разрешена только кириллица, тире и пробелы',
  NO_MORE_ONE_HUNDRED: 'Поле должно занимать не более 100 символов',
  COUNT_CONTRACT_FOR_CHECK: 'Количество комплектов к проверке',
  INVALID_PHONE: 'Невалидный номер',
  DOCUMENT_STATUS: 'Текущий статус комплекта:',
  INSURER: 'Страхователь',
  POLICY_NUMBER: 'Полис №:',
  PRINT_DOCUMENT: 'Печатать комплект',
  CANCEL: 'Аннулировать',
  SAVE: 'Подписать',
  CONTRACT_SAVED: 'Договор успешно сохранен',
  CONTRACT_ANNULLED: 'Контракт был успешно аннулирован',
  SELECT_DOCUMENT_TYPE: 'Выберите тип вложения',
  SIZE_ERROR: 'Файлы должны быть менее 20 Mb',
  TYPE_ERROR: 'Недопустимый формат файла',
  COUNT_OVERSIZE: 'Превышено максимальное количество файлов',
  FILES: 'Файлы',
  FILL_COMPLETELY: 'Заполните поле полностью',
  PASSPORT_EXPIRED: 'Паспорт просрочен, дальнейшее оформление невозможно',
  PASSPORT_NOT_VALID: 'Паспорт недействителен',
  PERSON_PASSPORT_RU: 'Паспорт гражданина РФ',
  SEARCH: 'Поиск',
  DATE_CONTRACT: 'Дата договора',
  CONTRACT: 'Договор',
  PRODUCT: 'Продукт',
  STATUS: 'Статус',
  STATUS_DOCUMENT: 'Статус договора',
  INSURANCE_SUM: 'Страховая сумма',
  PREMIUM: 'Взнос',
  CONTRACT_NUMBER: 'Номер договора',
  REPORT_DATE: 'Дата отчета',
  DOWNLOAD_REPORT: 'Выгрузить отчет',
  INSURER_FIO: 'ФИО Страхователя',
  ENTER_CONTRACT_NUMBER: 'Введите номер договора',
  START_DATE: 'Дата продажи с',
  CHECK_DATE: 'Дата проверки с',
  END_DATE: 'По',
  DATE_OF_CONCLUSION_FROM: 'Дата заключения с',
  START_SIGN_DATE: 'Дата подписания с',
  END_SIGN_DATE: 'Дата подписания по',
  TODAY: 'Сегодня',
  YESTERDAY: 'Вчера',
  CURRENT_WEEK: 'Текущая неделя',
  LAST_WEEK: 'Прошлая неделя',
  CURRENT_MONTH: 'Текущий месяц',
  CURRENT_YEAR: 'Текущий год',
  ALL_TIME: 'За все время',
  BACK_PAGE: '< Назад',
  INSURED: 'Застрахованные',
  BENEFICIARY: 'Выгодоприобретатели',
  FIO: 'ФИО',
  DUL: 'ДУЛ',
  DATE_OF_ISSUE: 'Дата выдачи',
  STRATEGY: 'Стратегия',
  DATE_BEGIN_CONTRACT: 'Дата начала действия',
  DATE_END_CONTRACT: 'Дата окончания действия',
  TERM_OF_INSURANCE: 'Срок страхования',
  PARTICIPATION_COEFFICIENT: 'Коэффициент участия',
  SPECIFY_AUTOCALL_BARRIER: 'Укажите барьер Автоколла',
  AUTOCALL_DESCRIPTION: 'При достижении указанного барьера ценами всех активов в корзине стратегия завершает действие. Если стратегия основная, то так же станет  доступен досрочный выход из продукта.',
  IF_9999_OPTION_DISABLED: 'При значении 10000 опция автоколла считается отключенной.',
  SPECIFY_BONUS_COUPON: 'Укажите размер Бонус-купона при Автоколле',
  BONUS_COUPON_DESCRIPTION: 'При срабатывании Автоколла выплачивается дополнительный купон в процентах от страховой премии.',
  NOTIONAL_BARRIER_DESCRIPTION: 'Барьер, при превышении которого срабатывает полная защита капитала',
  GEARED_BARRIER_DESTIPTION: 'Барьер, относительно которого считается просадка активов при падении ниже барьера на номинал',
  AUTOCALL_FIELD_NOT_AFFECT_CALCULATION_BONUS_COUPON: 'При Барьере автоколла 10000 данное поле не влияет на расчёт.',
  LOOKBACK_OPTION_DESCRIPTION: 'Опция, позволяющая выбрать  в качестве начальной цены актива минимальную из цен по перечню дат, отстоящих от даты инвестирования на заданной число дней',
  PRODUCT_DESCRIPTION: 'Описание продукта',
  PAYMENT_PERIODICITY: 'Периодичность уплаты',
  CONTRACT_SCANS: 'Сканы документов',
  INSURED_IS_INSURER: 'Застрахованный совпадает со страхователем',
  BENEFICIARY_LAW: 'Выгодоприобретатели указаны по закону',
  MY_DOCUMENTS: 'Мои продукты',
  MY_CUSTOMERS: 'Мои клиенты',
  HINT_TEXT: 'Текст подсказки',
  COUPON_FREQUENCY: 'Периодичность выплаты купона',
  ANNUAL_YIELD_COUPON: 'Ежегодная доходность купона',
  EXECUTE: 'Выполнить',
  ADMINISTRATION: 'Администрирование',
  TOTAL: 'Итого',
  REQUIRED_FIELD: 'Обязательно',
  REQUIRED_LABEL: '*',
  CHECK_DATA: 'Проверка данных',
  SAVE_WORD: 'Сохранить',
  WRITE_CAPTCHA: 'Пожалуйста, введите код с изображения',
  DONT_SEE_CODE: 'Не вижу код',
  YES: 'Да',
  NO: 'Нет',
  WORK_CONTINUE: 'Продолжить работу',
  CONTINUE_WORD: 'Продолжить',
  TIMEOUT_ACTIVITY: 'Таймаут активности',
  MESSAGE_CHANGE_PASSWORD_SUCCESS: 'Пароль успешно изменен. Войдите в систему, используя новый пароль',
  ACTIVE_ROLE: 'Активная роль',
  INHERIT_ROLE: 'Наследовать роль',
  FULL_NAME_CLIENTS: 'ФИО клиента',
  FULL_NAME_EMPLOYEE: 'ФИО сотрудника',
  TYPE_CLIENT: 'Тип клиента',
  CHECK_MASTER: 'Мастер проверки',
  TYPE: 'Тип',
  SUBDIVISION_CODE_SHORT: 'Код подр.',
  ATTENTION: 'Внимание',
  KIT: 'Комплект',
  CHECK_NOT_COMPLETELY: 'Проверен не полностью',
  QUESTION_NEXT_CONTRACT: 'Вы уверены, что хотите перейти к другому договору?',
  QUESTION_FINISH_CHECK: 'Вы уверены, что хотите закончить проверку?',
  BACK_WORD: 'Назад',
  FOLLOWING: 'Следующий',
  PREVIOUS: 'Предыдущий',
  STATUS_CONTRACT: 'Статус договора',
  NAMING: 'Наименование',
  KIT_CHECK: 'Состав проверки',
  UPDATE_DATA: 'Обновление данных',
  NO_SCANS_FOR_CHECK: 'Нет сканов к проверке',
  SINGULAR_INSURED: 'Застрахованный',
  SINGULAR_INSURED_CHILD: 'Застрахованный (ребенок)',
  SINGULAR_BENEFICIARY: 'Выгодоприобретатель',
  DESCRIPTION_ERROR: 'Описание ошибки',
  CONTRACT_NUMBER_OR_FIO: 'Номер контракта/ФИО',
  EXPORT_1C: 'Экспорт 1С',
  NO_ACCESS_PAGE: 'Нет доступа к данной странице',
  SELECT: 'Выбор',
  CANCEL_WORD: 'Отмена',
  SELECT_SUBDIVISION: 'Выберите подразделение',
  BANK_INSURER_DETAILS: 'Банковские реквизиты страхователя',
  SELECT_SUBDIVISION_AND_PARTNER: 'Выберите партнера/подразделение',
  SIMBOL_NUMBER: '№',
  FROM: 'От',
  MISSING: 'Отсутствует',
  NOT_ACTIVE_TIMEOUT: 'Вы слишком долго не проявляли активность',
  FINISH_SESSION: 'Закончить сессиию',
  SET_OF: 'Комплект из',
  MANAGMENT: 'Управление',
  STATE_CHECKING: 'Состояние проверки',
  SALES_MANAGER: 'Продавец',
  IMPORT_DATE: 'Дата импорта',
  DOWNLOAD_SHORT: 'Cкачать',
  YOU_ULOAD_NEW_DOCUMENT: 'Вы загружаете новую версию документа',
  SELECT_FILE: 'Выберите файл',
  HISTORY_DOWNOLADED_DOCUMENTS: 'История загруженных документов',
  SUCCESS_SCAN_UPLOAD: 'Скан успешно обновлен',
  ERRORS: 'Ошибки',
  GO_TO_HOME: 'Перейдите в главное меню',
  ERRORS_DOCUMENT: 'Ошибки в документах',
  TASKS_AND_LOCKS: 'Задачи и блокировки',
  CLEAR_LOCKS: 'Снять блокировку с проверки документов',
  SIGN_CONTRACT_SUCCESS: 'Договор успешно подписан',
  PRINT_ERROR: 'При создании контракта комплект не был распечатан. Печать не возможна',
  MESSAGE_DAILY_LIMIT: 'Превышены суммы страховых премий за сегодня, обратитесь на',
  NAMING_PARTNER: 'Наименование партнера',
  STATUS_SITE: 'Статус сайта',
  CREATE_PARTNER: 'Создать партнера',
  NAMING_BRANCHES: 'Название подразделения',
  CREATE_BRANCH: 'Создать подразделение',
  CREATE_USER: 'Создать пользователя',
  SHORT_NAMING: 'Сокращенное название',
  SET_OFFER: 'Комплект-оферта (статус)',
  INSURANCE_PASSPORT_MAIN_SPREAD: 'Паспорт страхователя – основной разворот (статус)',
  INSURANCE_PASSPORT_REGISTRATION: 'Паспорт страхователя – регистрация (статус)',
  INSURED_PASSPORT_MAIN_SPREAD: 'Паспорт застрахованного – основной разворот (статус)',
  INSURED_PASSPORT_REGISTRATION: 'Паспорт застрахованного – регистрация (статус)',
  STATUS_CHECK: 'Статус проверки',
  CHECK_TEST_CTS: 'Проверить тестовые контракты',
  LAST_DATE_UPDATE_DOCS: 'Дата последнего обновления документов',
  DATE_CHECK: 'Дата проверки',
  FULL_NAMING: 'Полное название',
  CONTRAGENT_NAMING: 'Наименование контрагента',
  INSURANCE_CONTRACT: 'Договор страхования (статус)',
  CODE_VALUE: 'Кодовое обозначение',
  LEGAL_TYPE: 'Тип юридический',
  OGRN_OR_OGRNIP: 'ОГРН/ОГРНИП',
  LINK_LOGO: 'Ссылка на файл логотипа',
  INCLUDE_PARTNER: 'Внутренний партнер',
  TYPE_CONTRACT: 'Тип договора',
  SHOW_ON_SITE_LIST_AGENT: 'Показывать на сайте в списке агентов',
  ACTIVED: 'Активен',
  ID: 'ID',
  CREATE: 'Создать',
  REVOKE: 'Отменить',
  INCOMING: 'Входящие',
  OUTGOING: 'Исходящие',
  PARTNER_CREATED: 'Партнер создан',
  PARTNER_UPDATED: 'Партнер обновлен',
  BRANCH_CREATED: 'Подразделение создано',
  BRANCH_UPDATED: 'Подразделение обновлено',
  NAMING_IN_DP: 'Наименование в ЦП',
  CODE_NAMING: 'Кодовое наименование',
  SHOW_ON_SITE_SHOR: 'Отобр. сайт',
  PARTNERS: 'Партнеры',
  VERSION_SITE: 'Версия сайта',
  VERSION_SERVER: 'Версия сервера',
  VERSION_BASE: 'Версия базы',
  OPEN_MIGRATION_TABLE: 'Открыть таблицу миграций',
  VERSION_DP: 'Версия ЦП',
  QUESTION_RESET_TYPE: 'Действительно ли вы хотите сбросить тип',
  QUESTION_CHANGE_TYPE: 'Действительно ли вы хотите поменять тип на',
  QUESTION_DELETE_FILE: 'Действительно ли вы хотите удалить файл',
  FULL_TREE_BRANCH: 'Полное древо подразделений',
  ADDRESS: 'Адрес',
  IS_ROOT_BRANCH: 'Является корневым подразделением',
  PARENT_BRANCH: 'Родительское подразделение',
  BRANCH_ADDRESS: 'Адрес подразделения',
  NAMING_BRANCH: 'Наименование подразделения',
  BRANCHES: 'Подразделения',
  NETWORK_MODE: 'Сетевой режим',
  LOCAL_DIR: 'Локальное расположение ',
  NETWORK_LOCATION: 'Сетевое расположение',
  START_IMPORT: 'Импорт запущен',
  WITHOUT_ADDRESS_DATA: 'Без адресных данных',
  WITHOUT_CONTACT_DATA: 'Без контактных данных',
  WITHOUT_DOCUMENT_DATA: 'Без документов',
  BENEFICIARY_DATA: (index) => `Данные ${index} выгодоприобретателя`,
  USERS: 'Пользователи',
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  OCATO_LENGTH: 11,
  PARTNERS_PREFIX: 'partners',
  STATUS_ACTIVE_PREFIX: 'statusActive',
  UUID: 'UUID',
  HASH: 'Хэш',
  DATE_OR_TIME_CREATE: 'Дата/время создания',
  DATE_OR_TIME_LOCK: 'Дата/время блокировки',
  DATE_OR_TIME_UNLOCK: 'Дата/время разблокировки',
  DATE_OR_TIME_START_BAN: 'Дата/время начала бана',
  TEMP_BAN: 'Продолжительность бана',
  BAN_USER: 'Забанить',
  UNBAN_USER: 'Разбанить',
  PARTNER_U_ID: 'ID у партнера',
  PARTNER_ENUM: 'Должность/Табельный номер',
  SHOW_ON_SITE: 'Отображать на сайте',
  USER_INFO_CARD: 'Карточка пользователя',
  CREATE_REPORT: 'Создать отчет',
  REPORT_TYPE: 'Тип отчета',
  ROLES: 'Роли',
  REPORT_CARD: 'Карточка отчета',
  FIELD_NAMING: 'Наименование полей',
  REPORT_TAGS: 'Тэги отчета',
  REPORT_CREATED: 'Отчет создан',
  REPORT_UPDATED: 'Отчет обновлен',
  PLACEHOLDER_DRAG_LIST_REPORTS: 'Переместите в данную область теги для формирования отчета',
  REPORT_NAMING: 'Наименование отчета',
  CHOOSE_REPORT: 'Выберите отчет',
  REPORTS: 'Отчеты',
  CODE_OKATO: 'Код ОКАТО',
  PAGE_NOT_FOUND_CODE: '404',
  PAGE_NOT_FOUND_TEXT: 'К сожалению, запрашиваемая вами страница не найдена. Возможно, вы прошли по устаревшей ссылке или ввели некорректный адрес',
  BACK_TO_MAIN_PAGE: 'Перейти на главную',
  READY_TO_ORDER_CONSTRUCTOR_BASKETS: 'Готовые к оформлению корзины',
  POPULAR_CONSTRUCTOR_BASKET: 'Регулярные корзины',
  GET_ANOTHER_CONSTRUCTOR_BASKET: 'Собрать продукт',
  GET_ANOTHER_CONSTRUCTOR_BASKET_AGAIN: 'Собрать продукт снова',
  LAST_QUERYES_FOR_USER: 'Осталось запросов: ',
  STOCK_BASKET: 'Корзина акций',
  CURRENCY: 'Валюта',
  PAYOUT: 'Выплата',
  BARRIERS: 'Барьеры',
  LOOKBACK: 'Лукбэк',
  COUPON: 'Купон',
  COUPON_MORE: 'Купон (взнос от 1.5 млн.руб. включительно)',
  AUTOCALL_BARRIER: 'Барьер автоколла',
  NOTIONAL_BARRIER: 'Барьер на номинал',
  GEARED_BARRIER: 'Барьер опции geared put',
  AUTOCALL_BONUS_COUPON: 'Бонус-купон при автоколле',
  PROTECTION_COUPON: 'Защитный купон',
  DOING_END: 'Действует до',
  COUPON_LESS: 'Купон (взнос до 1.5 млн.руб.)',
  PARTITION_COEFFICIENT_LESS: 'Коэффициент участия (взнос до 1.5 млн.руб.)',
  FAVORITES: 'Избранное',
  CHOOSE_PARAMETRES: 'Указание параметров',
  MAXIMUM_ASSETS: 'Максимальное допустимое количество активов - 6 штук',
  CHOOSE_ASSETS: 'Выберите активы',
  MAX_ASSETS: (max) => `максимум ${max}`,
  MINIMUM_PAY_REQUEST: 'Минимальный взнос',
  KNOW_MINIMUM_PAY_REQUEST: 'Узнать минимальный взнос',
  CHOOSE_CURRENCY_CONTRACT: 'Выберите валюту договора',
  CHOOSE_PERIOD_CONTRACT: 'Выберите срок договора',
  CHOOSE_BARRIERS_LEVELS: 'Выберите уровни барьеров',
  CHOOSE_PAYOUT_TIMES_CONTRACT: 'Выберите частоту выплат купонов',
  BARRIERS_MINMAX: (minLevel, maxLevel) => `Значения барьеров должны лежать в диапазоне от ${minLevel} до ${maxLevel}`,
  BARRIER_LEVEL_RESTRICTION_ERROR: 'Нет минимального или максимального значения для данного параметра',
  REQUEST_COUPON: 'Рассчитать',
  PAYMENT_TERM_MONTH: 'Срок выплаты (мес.)',
  ROUBLE: 'ruble',
  DOLLAR: 'dollar',
  ROUBLE_WORD: 'Рубль',
  DOLLAR_WORD: 'Доллар',
  MY_QUERYES_CONSTRUCTOR_BASKET: 'Рассчитанные индивидуальные корзины',
  READY_BASKETS: 'Готовые корзины',
  READY_TO_ORDER_INDIVIDUAL_BASKETS: 'Рассчитанные индивидуальные корзины',
  BACK_TO_CONSTRUCTOR: 'Вернуться в конструктор',
  ORDER_READY_BASKET: 'Оформить полис',
  ASSETS_BASKET: 'Корзина акций',
  PAYOUT_TIMES: 'Частота выплат',
  BASKET_POPUP_TEXT_CALCULATED: 'Купон для Вашего продукта уже рассчитан! Вы можете увидеть его в таблице «Рассчитанные индивидуальные корзины».',
  BASKET_POPUP_TEXT_CALCULATED_KU: 'Рассчитаный коэффициент участия для Вашего продукта:',
  BASKET_POPUP_TEXT_CALCULATED_COUPON: 'Рассчитанный купон для Вашего продукта:',
  FOR_CONTRIBUTION_OF: (sum) => `Для взноса от ${sum} млн.руб.: `,
  FOR_CONTRIBUTION_OF_UP_TO: (sum) => `Для взноса до ${sum} млн.руб.: `,
  BASKET_POPUP_TEXT_IN_PROGGRES: 'Ваш запрос на расчет отправлен. Статус можно отслеживать в таблице «Рассчитанные индивидуальные корзины».',
  BASKET_POPUP_TEXT_NOT_PROFITABLE: 'Ошибка! Корзина собрана со слишком низкой доходностью для клиента. Нужно изменить параметры.',
  REFRESH_PAGE_POPUP_TEXT: 'Версия сайта обновлена. Пожалуйста, обновите страницу.',
  REFRESH_TEXT: 'Обновить',
  TRADE_DATAS: 'Обмен данными',
  EXPORT_IN_1C: 'Экспорт в 1С',
  IMPORT_FROM_1C: 'Импорт из 1С',
  IMPORT_TYPE: 'Режим импорта',
  MANUAL_IMPORT: 'Ручной',
  AUTOMATICALY_IMPORT: 'Автоматический',
  CHOOSE_FILE_FOR_IMPORT: 'Выбор файла для импорта',
  CONTRACT_NUMBER_FOR_IMPORT: 'Номер договора к импорту (опционально)',
  CHOOSE_CONTRACT_STATUS_FOR_IMPORT: 'Выберите определенный статус договоров к обновлению (опционально)',
  CHOOSE_ALL_OR_ONE_PARTNER_FOR_IMPORT: 'Выберите всех или определенного партнера для импорта (опционально)',
  EXECUTE_IMPORT: 'Произведите импорт',
  DESCRIPTION_IMPORT_FROM_1C: 'Интерфейс импорта данных с 1С Ортикон УСК',
  SETTINGS_IMPORT: 'Настройка импорта',
  DIRECTORY_FOR_NEW_FILES: 'Директория для новых файлов',
  DIRECTORY_FOR_ERROR: 'Директория если ошибка',
  DIRECTORY_FOR_SUCCESS: 'Директория если успешно',
  SEARCH_CONTRACT: 'Поиск договора',
  CURRENT_STATUS_CONTRACT: 'Текущий статус',
  CURRENT_STATE_CONTRACT: 'Текущее состояние',
  PAYOUT_PERIODS_CONTRACT: 'Периодичность платежей',
  PAYOUT_CONTRACT: 'Платеж',
  CONTRACT_PREMIUM: 'Премия',
  PAYOUTED_CONTRACT: 'Оплачено',
  PAYOUT_STATUS: 'Статус оплаты',
  EXECUTE_CHANGES_CONTRACT: 'Произведите изменение',
  STATUS_MANAGMENT: 'Управление статусами',
  CONTRACTES: 'Договоры',
  DESCRIPTION_CONTRACTS_CONTROL: 'Интерфейс управления статусами и состояниями договоров',
  WHEN_FILE_IMPORT_FROM_1C: 'При импорте файла',
  ERRORS_WHEN_FILE_IMPORT_FROM_1C: 'призошли следующие ошибки:',
  SUCCESS_WHEN_FILE_IMPORT_FROM_1C: 'импорт завершился успешно',
  CONTRACT_FOR_IMPORT_NOT_FOND: 'Договор по выбранным параметрам не найден',
  REPEAT_EVEY_N_MINUTE: 'Повтор каждые N минут:',
  WAITING_FOR_PAY: 'Ожидает оплаты',
  ERROR_FOR_PAY: 'Ошибка',
  PARTLY_PAYED: 'Частично оплачен',
  PAIDED_FULL: 'Полностью оплачен',
  CONSTRUCTOR_PERIOD_WORD: 'period',
  CONSTRUCTOR_PAYOUT_WORD: 'payout',
  REBUILD_CONTRACTS_COMPLECT: 'Перегенерировать комплект',
  COPY_CONTRACT: 'Копировать',
  BASKETS_NOT_FOUND: 'Корзины не найдены.',
  STATUS_SAVED: 'Изменения сохранены',
  NOTIFICATION_WORD: 'Уведомления',
  DATE_REMOVE_CONTRACT: 'Дата расторжения',
  PRINT_OTHER_DOCUMENTS: 'Дополнительные материалы',
  INNER_BRANCH_CODE_BANK: 'Внутренний код подразделения в банке',
  ACTIVE_DISTRIBUTING: 'Дистрибуция активна',
  START_PRODUCTS_SALE: 'Начало продаж',
  ENDING_PRODUCTS_SALE: 'Окончание продаж',
  CANCEL_FOR_ALL_PRODUCTS: 'Отключить всем',
  ACTIVATING_FOR_ALL_PRODUCTS: 'Включить всем',
  CHANGES_SAVE: 'Изменения сохранены',
  CHANGES_CANCELED: 'Изменения отменены',
  ADD_ROW: 'Добавить запись',
  DISTRIBUTING_PRODUCTS: 'Дистрибуция',
  SCHEDULER_JOBS: 'Планировщик задач',
  ACTIVE_JOBS: 'Активные задачи',
  PROCCESS_JOBS: 'Запущенные задачи',
  COMPLETE_JOBS: 'Завершенные задачи',
  FAILED_JOBS: 'Завершенные с ошибкой',
  PRIORITY_WORD: 'Приоритет',
  DESCRIPTION_WORD: 'Описание',
  TIME_START_WORD: 'Время запуска',
  CONTROL_START_PAUSE_JOB: 'Пуск/Пауза',
  NUMBER_OF_TRYIES: 'Количество попыток',
  TIME_END_WORD: 'Время завершения',
  ERROR_TEXT_WORD: 'Текст ошибки',
  STOP_ACTIVE_TASK: 'Остановить выполнение этой задачи?',
  DOCUMENT_TYPE_PASSPORT: 1,
  DOCUMENT_TYPE_BIRTH_DOCUMENT: 2,
  DOCUMENT_TYPE_TEMP_CERT: 5,
  INSERT_PASSWORD_FOR_CHANGE: 'Введите пароль для изменения',
  CHOOSE_PRODUCT_FOR_SEARCH: 'Выберите продукт для поиска',
  REFERENCES_ADMIN_INTERFACE: 'Справочники',
  IS_EMPLOYER: 'Сотрудник СК',
  PRODUCTS_WORD: 'Продукты',
  PRODUCT_NAME_WORD: 'Наименование продукта',
  CHOOSE_TIME_START_FOR_JOB: 'Выберите периодичность выполнения задачи',
  MY_SALES: 'Мои продажи',
  DOCUMENT_CHECK_STATUS: 'Статус проверки документов',
  CUSTOMER_REPORTING_SERVICE: 'Клиентские отчёты',
  INTERNAL_DOCUMENT_TRANSFER: 'Документооборот',
  INSERT_PAYOUTED_SUM: 'Введите оплаченную сумму',
  TIME_WORD: 'Время',
  INTERVAL_WITH_TIME: 'Интервал (минут)',
  WARNING_MESSAGE_DIFFERENT_COLORS_ASSETS: 'Добавление в корзину активов с новым цветом может изменить минимально необходимую сумму для оформления продукта',
  NOT_FOUND_DATA: 'Данные отсутствуют',
  IS_EMPLOYEE: 'isEmployee',
  NOTIFY_CLIENTS_OF_CALCULATED_BASKETS: 'Оповестить клиентов о расчитанной корзине',
  BASKET_CALCULATED: 'Ваша корзина расчитана',
  BASIC_RISKS_PACKAGE: 'Базовый пакет рисков',
  ADDITIONAL_RISKS: 'Дополнительные риски',
  COUNT_TYPE: 'Тип расчета',
  SIZE_REGULAR_ENTER: 'Размер регулярного взноса',
  AMOUNT_SUM: 'Сумма накопления',
  ADDITIONAL_RISKS_PACKAGE: 'Пакет дополнительных рисков',
  CHILD_GENDER: 'Пол застрахованного ребенка',
  CHILD_BIRTH_DATE: 'Дата рождения застрахованного ребенка',
  CONTRACT_TEMP: 'Срок действия договора (лет)',
  COUNT_WORD: 'Рассчитать',
  VALIDATE_INSURED_AGE: (min, max) => `Возраст застрахованного должен быть больше или равен ${min} и меньше или равен ${max}`,
  VALIDATE_INSURED_CHILD_AGE: (min, max) => `Возраст застрахованного ребенка должен быть больше или равен ${min} и меньше или равен ${max}`,
  VALIDATE_INSURANCE_SUM: (Smin, Smax) => `Размер суммы накопления должен быть больше или равен ${Smin} и меньше или равен ${Smax}`,
  VALIDATE_ADDITIONAL_RISK: (countWithUnit) => `Защита по этому риску будет действовать только первые ${countWithUnit} действия договора`,
  VALIDATE_RISK_WEIGHT: (minWeight, maxWeight) => `Вес риска должен быть больше или равен ${minWeight} и меньше или равен ${maxWeight}`,
  NOT_FOUND_REGULAR_SUM: 'Не найдены огранчения размера регулярного взноса',
  NOT_FOUND_INSURANCE_SUM: 'Не найдены ограничения для суммы накопления',
  PRINT_ORDER: 'Распечатать отчет',
  PRINT_PAYMENT: 'Распечатать расчет',
  PAGE_NOT_FOUND: 'Страница не найдена',
  CHILD_REALTIONSHIPT_TO_INSURER: 'Родственные отношения застрахованного ребенка к страхователю',
  CHILD_DOCUMENTS: 'Документ, удостоверяющий личность ребенка',
  INSURED_CHILD_DATA: 'Данные застрахованного ребенка',
  CHILD_ADRES_FIAS: 'Адрес регистрации ребенка',
  CHILD_ADRES_FIAS_LIVING: 'Адрес проживания ребенка',
  CHILD_CONTACTS: 'Контактные данные ребенка',
  ADDITIONAL_RISKS_NOT_FOUND: 'Дополнительные риски недоступны для подключения',
  DEFAULT_CONTRY_RUSSIA: 'Россия',
  PERCENT_OF_THE_SUMMARY_OF_SURVIVAL: '% от суммы по дожитию',
  INSURER_AND_INSURED_CANNOT_MATCH_AS_THERE_ARE_DIFFERENT_GENERAL_OR_DATE_OF_BIRTH: 'Страхователь и застрахованный не могут совпадать, так как имеют разный пол или дату рождения',
  INSURER_AND_INSURED_CANNOT_MATCH_AS_THERE_ARE_DIFFERENT_DATE_OF_BIRTH: 'Страхователь и застрахованный не могут совпадать, так как имеют разную дату рождения',
  DOWNLOAD_FILE: 'Загрузка файла',
  FILE_NAME: 'Имя файла',
  WARNING_CYRILLIC_LATIN_DEF_NUMBERS: 'Поле может содержать латиницу, кириллицу, цифры, пробелы и тире',
  ADD_IP_ADDRESS: 'Добавить IP-адрес',
  SAVED: 'Сохранено',
  REGENERATE_SECRET_KEY: 'Перегенерировать секретный ключ',
  WARNING_SAVE_PUBLIC_API_KEY: 'Скопируйте и сохраните секретный ключ, у Вас больше не будет возможности его получить',
  API_KEY: 'API-ключ',
  SECRET_KEY: 'Секретный ключ',
  DATE_UPDATE_SECRET_KEY: 'Дата обновления секретного ключа',
  ACCESS_WITHOUT_RESTRICTION_IP_ADDRESS: 'Доступ без ограничений по IP-адресу',
  AVAILABLE_API: 'Доступные API',
  SELECT_AVAILABLE_API: 'Выберите доступные API',
  SELECT_PARTNER: 'Выберите партнера',
  SELECT_BRANCH: 'Выберите подразделение',
  SELECT_USER: 'Выберите пользователя',
  SELECT_RECIPIENT: 'Выберите получателя',
  ENTER_COMMENT: 'Введите комментарий',
  ENTER_MESSAGE: 'Введите сообщение',
  MESSAGE: 'Сообщение',
  SENDER: 'Отправитель',
  SEND: 'Отправить',
  PUBLIC_API: 'Публичный API',
  ENTER_DESCRIPTION: 'Введите описание',
  UPDATE_BAD_INSURERS_LIST: 'Загрузить список неблагонадежных страхователей',
  ERROR_FURTHER_PROCESSING_IS_NOT_POSSIBLE: 'Ошибка. Дальнейшее оформление невозможно.',
  SWITCH_MANUAL_ENTER: 'Переключиться на ручной ввод',
  SWITCH_AUTO_ENTER: 'Переключиться на автоматический ввод',
  GUARANTEE_LEVEL: 'Уровень гарантии',
  OF_THE_AMOUNT_OF_THE_PAID_CONTRIBUTION: 'от суммы уплаченного взноса',
  VALUE_MUST_BE: 'Значение должно быть',
  VALUE_MUST_BE_NO_LEST: 'Значение должно быть не меньше',
  VALUE_MUST_BE_NO_MORE: 'Значение должно быть не более',
  WARNING_GUARANTEE_LEVEL: (guaranteeLevel) => `Вы переходите к оформлению полиса с уровнем гарантии, равным ${guaranteeLevel}%`,
  SET_FILTER_CONSTRUCTOR: 'Задайте фильтрующее значение для поля',
  CLEAR_FILTER_SORT: 'Сбросить фильтры и сортировки',
  CHANGE_BASKET: 'Изменить продукт',
  NOT_FOUND: 'Не найдено',
  BASKET_TERM_HELP_MESSAGE: 'Срок действия инвестиционной стратегии. От выбранного срока может зависеть доступность некоторых валют.',
  BASKET_ASSETS_HELP_MESSAGE: 'Ценные бумаги, к изменению стоимости которых будет привязан инвестиционный доход клиента.',
  BASKET_MIN_AMOUNT_HELP_MESSAGE: 'Минимальная сумма для оформления полиса. Зависит от выбранных базовых активов в корзине: для бумаг, включенных аналитиками в список рекомендованных (выделены зеленым цветом), минимальная сумма взноса будет меньше.',
  BASKET_LEVEL_BARRIERS_HELP_MESSAGE: 'Пороги в процентах от начальной цены, с которым сравниваются цены активов в даты наблюдения. Чем ниже барьеры, тем выше вероятность получить купон.',
  BASKET_PAYOUT_COUPON_HELP_MESSAGE: 'Частота наблюдения и выплат инвестиционного дохода. Чем чаще происходит наблюдение, тем выше вероятность возникновения условий для выплаты купона.',
  REPORT_BASKETS_NAME: 'Отчет_о_корзинах',
  UNKNOWN_TYPE_RISK: 'Неизвестный тип рисков',
  ALL_RECORDS_IN_THE_TABLE_WIL_BE_OVERWRITTEN_WITH_NEW_DATA: 'Все записи в таблице будут перезаписаны новыми данными. Продолжить?',
  OVERWRITTEN_DATA: 'Перезаписать данные',
  AMOUNT_SHOULD_BE: 'Сумма должна быть',
  SELECT_OPTION_CONSTRUCTOR: 'Выберите опцион',
  PARTITION_COEFFICIENT: 'Коэффициент участия (взнос от 1.5 млн.руб. включительно)',
  DATE_CREATE: 'Дата создания',
  PRODUCT_NUMBER: 'Номер продукта',
  SELECT_OPTION_TYPE_CONSTRUCTOR: 'Укажите тип опциона',
  BONUS: 'Бонус',
  CLUSTER: 'Кластер',
  PLACE_IN_THE_RANKING: 'Место в рейтинге',
  PLACE: 'Место',
  SALES: 'Продажи',
  NUMBER_OF_AGREEMENTS_SOLD: 'Количество проданных договоров',
  QUANTITY_ITEM_SHORT: 'шт.',
  AVERAGE_CHECK: 'Средний чек',
  COLLECTION_PLAN: 'План по сборам',
  IMPLEMENTATION_OF_A_PLAN: 'Выполнение плана',
  SUBDIVISION_ABBREVIATION: 'ДО',
  FEES: 'Сборы',
  MIN_LIMIT: 'Мин. порог',
  RESULTS_FOR: 'Результаты на',
  CONTEST_RESULT: 'Итоги конкурса',
  CONTEST_DOWNLOAD: 'Скачать',
  DATE_BEGIN_TERM_CREDIT: 'Дата начала срока кредита',
  TERM_OF_CREDIT: 'Срок действия договора',
  TERM_MONTH: 'мес',
  CREDIT_AMOUNT: 'Первоначальная сумма кредита',
  DATE_BEGIN_CREDIT: 'Дата начала действия договора',
  DATE_END_CREDIT: 'Дата окончания действия договора',
  INSURANCE_FEE: 'Страховой взнос',
  BRANCH_TUTOR: 'Куратор подразделения',
  NOT_FOUND_RESULTS: 'Результатов не найдено',
  UNITED_FRONTEND_BLOCK_MESSAGE: 'Текущее окно Цифровой Платформы больше не активно. Начните оформление в новом окне.',
  ENTRANCE_FROM_UNITED_FRONTEND: 'Вход с Единого фронта',
  UNITED_FRONTEND_WITHOUT_MASTER_ID: 'Внимание! Оформление продуктов по данной ссылке невозможно. Пожалуйста, осуществите вход через Единый Фронт повторно или обратитесь в техническую поддержку.',
  DECLARATION_CONFIRM: 'Подтверждаю декларацию',
  PRODUCT_VERSION: 'Версия продукта',
  EXECUTION_NOT_FINISHED_IN_OTHER_WINDOW: 'Возможно, Вы не завершили оформление в другом окне. Хотите закрыть текущее окно или продолжить оформление в нем',
  IN_CURRENT_WINDOW: 'В текущем окне',
  OLD_SESSION_CLOSED: 'Прошлая сессия будет закрыта',
  CLOSE_CURRENT_WINDOW: 'Закрыть \n текущее окно',
  NEW_WINDOW: 'Новое окно',
  LOCK_UNITED_FRONT: 'Дальнейшее оформление полиса доступно только при авторизации по ссылке, сгенерированной на внешнем ресурсе',
  READ_MODE: 'режим просмотра',
  WARNING_FOR_BIG_POLICY_SUM: (sumWithCurrency) => `Для оформления договора на сумму свыше ${sumWithCurrency} требуется индивидуальное согласование. Обратитесь к коучу, либо по почте operu@bcslife.ru`,
  WARNING_FOR_CORRECTION_COEF: 'Ошибка настройки коэффициента коррекции',
  UPLOAD_TO_BROWSER: 'Выгрузить в браузер',
  UPLOAD_ADDITIONAL_PRINT_FORM: 'Выгрузить дополнительные печатные формы',
  INVESTMENT_WORLD: 'Мир инвестиций',
  UNITED_FRONT: 'Единый фронт',
  SELECT_ALL: 'Выбрать все',
  SELECT_TYPE_CONTRACTS: 'Выберите тип контрактов',
  TECH_SUPPORT: 'Техническая поддержка',
  STRATEGY_NUMBER: 'Стратегия №',
  MAIN_WORD: 'Основная',
  ADD_IN_PRODUCT_STRATEGY: 'Добавить в продукт еще одну стратегию',
  TEMPLATE_UPLOADED: 'Загрузка успешно завершена',
  TEMPLATE_UPLOAD_ERROR: 'Ошибка загрузки',
  UPLOAD_TEMPLATES_TITLE: 'Загрузка печатных форм',
  UPLOAD_TEMPLATE_INTERFACE: 'Интерфейс загрузки печатной формы',
  UPLOAD_TEMPLATE_BUTTON: 'Загрузить печатную форму',
  TEMPLATE_ALREADY_EXIST: (name) => `Файл ${name} уже существует на сервере. Хотите перезаписать?`,
  TEMPLATE_CHECK_RESULT: 'При проверке файла печатной формы были найдены следующие ошибки:',
  AUTOCALL_IS_ENABLED: 'Включена опция Автоколл: при соблюдении определенных условий договор можно будет завершить досрочно.',
  AUTOCALL_IS_NOT_ENABLED: '«Опция Автоколл на договоре НЕ ВКЛЮЧЕНА – при достижении барьера стратегия просто завершит свое действие. Чтобы добавить опцию досрочного выхода из договора, нужно указать Тип стратегии с автоколлом на основной стратегии».',
  SELECT_STRATEGY_TYPE: 'Укажите тип стратегии',
  SELECT_STRATEGY_TERM: 'Выберите срок стратегии',
  STRATEGY_CURRENCY: 'Валюта стратегии',
  PRODUCT_TYPE: 'Тип продукта',
  COPY: 'Скопировать',
  DELETE: 'Удалить',
  COUPON_PERCENT_YEAR: 'Купон, в % годовых',
  BASKET_HELP_MESSAGE_COEFFICIENT_OR_COUPON: 'По дополнительным стратегиям есть возможность указать явно желаемый купон или коэффициент участия. В зависимости от указанного значения будут рассчитаны условия по основной стратегии',
  VALUE_MUST_BE_NUMBER_DIAPASON_0_999_WITH_TWO_DECIMAL: 'Значение должно быть числом от 0 до 999 с точностью до двух знаков после запятой',
  MAIN_STRATEGY: 'Основная стратегия',
  ONLY_FAVORITE: 'Только избранные',
  STRATEGY_TYPE: 'Тип стратегии',
  DATA_GETTING: 'Получение данных',
  ADDITIONAL_WORD: 'Дополнительная',
  APPLY_WORD: 'Применить',
  WARNING_CHILD_STRATEGY_TERM_MORE: 'Сроки действия дополнительных стратегий не должны быть больше срока основной стратегии',
  WARNING_LIMIT_MAX_STRATEGIES: (count) => `Превышено ограничение на количество корзин в группе для выбранного типа продукта (не более ${count})`,
  WARNING_EXECUTE_CALCULATING_BASKET: 'Для оформления должна быть выделена основная корзина, а также все выделенные дополнительные корзины должны являться дочерними к выделенной основной',
  IS_TEST: 'Тестовое',
  IS_TEST_BRANCH: 'Является тестовым подразделением',
  FILL_TEST_DATA: 'Заполнить тестовыми данными',
  EXPORT_TEST_CTS: 'Выгрузить тестовые контракты',
  IS_NEED_ACCEPT_RULES: 'Необходимо принять соглашения партнерского канала',
  AVAILABILITY_OF_CLIENT_REPORTS: 'Доступность клиентских отчётов',
  IS_PARTNER_REFERS_TO_BCS_SZ: 'Партнер относится к организации БКС СЖ',
  IS_ACCESS_WITHOUT_IP_RESTRICTIONS: 'Доступ без ограничений по IP',
  PARTNER_CHANNEL_FIRST_AGREEMENT: 'Я ознакомлен с условиями и присоединяюсь к',
  PARTNER_CHANNEL_FIRST_AGREEMENT_LINK: 'Агентскому договору (присоединение)',
  PARTNER_CHANNEL_SECOND_AGREEMENT: 'Я ознакомлен с',
  PARTNER_CHANNEL_SECOND_AGREEMENT_LINK: 'Офертой',
  OK_WORD: 'ОК',
  PERIOD_PAYMENT_FREQ: 'Периодичность уплаты взносов',
  SIZE_OF_POLICY_AMOUNT: 'Размер страхового взноса',
  FINAL_INSURANCE_PREMIUM: 'Итоговая страховая премия',
  INSURANCE_AMOUNT_FOR_FINAL_LIFE: 'Страховая сумма по дожитию / Защита капитала',
  INSURANCE_AMOUNT_FINAL_SUM_ERROR: (min, max) => `Страховая сумма по дожитию должна быть больше или равна ${min} и меньше или равна ${max}`,
  MASS_SEGMENT: 'Масс-сегмент',
  SCANS_REQUIRED: 'Сканы требуются',
  PRODUCT_NAME: 'Наименование продукта',
  CONTR_AGENT_ADDRESS: 'Адрес регистрации контрагента',
  CONTRACTS_SOURCE: 'Источник контрактов',
  POLICY_EXECUTION: 'Оформить полис',
  ALL_INVESTMENT_PRODUCTS: 'Все инвестиционные продукты',
  WARNING_BASKET_GUARANTEE_LEVEL_DOES_NOT_FIT: 'Созданная Вами корзина не доступна для оформления с текущим выставленным значением Защиты капитала',
  WARNING_BASKET_NEGATE_COUPON: 'Рассчитанный по Вашему продукту купон отрицательный: продукт доступен к оформлению только при положительных купонах. Для этого нужно уменьшить уровень Защиты капитала или заложить более низкую доходность по дополнительным стратегиям.',
  UNSTABLE_INTERNET_CONNECT: 'Нестабильное интернет-соединение',
  REPORTING: 'Отчетность',
  CHART: 'График',
  TECHNICAL_ANALYSIS: 'Технический анализ',
  BROWSE_NOT_SUPPORTED_WIDGET: 'Извините, Ваш браузер не поддерживает отображение виджетов.',
  UNABLE_CHECKOUT_PRODUCT: 'Невозможно оформить продукт с отрицательным купоном или коэффициентом участия',
  SELECT_FEATURE: 'Выберите, по какому параметру осуществлять поиск и, в зависимости от выбранного параметра, введите данные по Клиенту, затем нажмите "Поиск". Если по Клиенту было найдено более одного договора, необходимо выбрать нужный из списка. Укажите дату отчета и нажмите кнопку "Выгрузить отчет".',
  PREM_REG_TOTAL: 'Сумма оплаченных взносов за весь срок',
  TAX_DEDUCTION: 'Сумма налоговых вычетов (в рублях)',
  EXPECTED_INCOME: 'Ожидаемый размер индексации страховой суммы по рискам "Дожитие" или "Смерть ЛП" по окончании действия договора (в валюте договора)',
  EDIT_BASKET: 'Изменить корзину',
  CONTINUE_REGISTRATION: 'Продолжить оформление',
  PRODUCT_PRESENTATION: 'Презентация по продукту',
  DOWNLOAD_PRESENTATION_WARNING: 'Для выгрузки презентации убедитесь, что поля "Версия продукта" (если версионность доступна на продукте), "Срок продукта", "Периодичность взноса", "Размер страхового взноса" заполнены',
  ANNUL_CONTRACT: 'Вы уверены, что хотите аннулировать договор?',
  REPORT_INFO_TEXT: 'Дата отчёта может быть не ранее даты инвестирования и не позднее даты актуальности отчета',
  MIN_INSURANCE_AMOUNT_TABLE_VERSION: 'Версия',
  MIN_INSURANCE_AMOUNT_TABLE_VALUE: 'Минимальный взнос',
  UNEQUAL_ASSET_CLASS: 'Не может быть выбран в одну корзину с уже выбранными активами',
  ASSETS_HAVE_DIFFERENT_CLASSES: 'Активы в составе выбранной корзины имеют разные классы',
  SEND_CREDENTIALS: 'Сохранить и направить креды',
  SEND_CREDENTIALS_SUCCESS: 'Креды сохранены и отправлены пользователю',
  SEND_CREDENTIALS_ERROR: 'Ошибка отправки кредов',
  GUARANTEE_LEVEL_WARNING: (maxGuaranteeLevel) => `Корзина будет посчитана с уровнем защиты ${maxGuaranteeLevel}%. Пересчёт для другого уровня защиты можно сделать, поменяв поле «Уровень гарантии» на следующем экране`,
  ADD_GUARANTEED_PAYOUT: 'Добавить гарантированную выплату',
  SCHEDULE_GARANT_PAYMENTS: 'График гарант-выплат',
  PAYMENT_TERM: 'Срок выплаты',
  GUARANTEED_PAYMENTS_AMOUNT: 'Размер гарантированной выплаты',
  SPECIFY_THE_TERM: 'Укажите срок основной стратегии',
  TERM_ANNOTATION: 'Срок указывается в виде целого числа месяцев с даты начала договора страхования, но не более срока страхования',
  GUARANTEED_PAYMENT_ANNOTATION: 'Выплата указывается в виде целого/десятичного числа процентов от страховой премии',
  FIXED_INCOME_PERC_WARNING: (fixedIncomePerc) => `Корзина была посчитана с уровнем защиты капитала ${fixedIncomePerc === null ? 0 : fixedIncomePerc}%. Чтобы её оформить нужно обновить расчет для выставленного значения в поле «Уровень гарантии»`,
  AGENT_CHECKING_ERROR: 'Контрагент не прошел проверку',
  AGENTS_CHECKING_ERROR: 'Контрагенты не прошли проверку',
  INSURER_PASSPORT_SERIES: 'Серия паспорта Страхователя',
  PASSPORT_SERIES: 'Серия паспорта',
  INSURER_PASSPORT_NUMBER: 'Номер паспорта Страхователя',
  PASSPORT_NUMBER: 'Номер паспорта',
  DP_POLICY_BASKET_SENT: 'Корзина отправлена в Мир Инвестиций',
  SEND_TO_DP_POLICY: 'Отправить в Мир Инвестиций',
  SELECT_PRODUCT_VERSION: 'Выберите версию продукта',
  DP_POLICY_EXECUTION_MESSAGE: 'Корзина отправлена клиенту в приложение «Мир Инвестиций». Время доставки до 5 минут.',
  INCORRECT_PASSWORD_ERROR: 'Неправильный логин или пароль',
  RESTOR_PASSWORD: 'Забыли пароль?',
  RESTOR_PASSWORD_SUCCESS: 'Ваша заявка на восстановление пароля передана в службу тех. поддержки, ожидайте ответа на ваш e-mail.',
  PRODUCT_EXECUTION_DISABLE: 'Оформление продукта невозможно',
  RISK_PROFILE_ERROR_MESSAGE: 'Для данного риск-профиля продукт с указанным уровнем гарантии недоступен для оформления',
  MASTER_ID: 'Мастер ID',
  CLIENT: 'Клиент',
  SEND_LOGIN_RECOVERY_TITLE: 'Заполните данные для восстановления доступа',
  SEND_LOGIN_REVOVERY: 'Отправить заявку',
  QUALIFICATION_ERR: 'Продукт не доступен для данного клиента из-за ограничений квалификации',
  REDEMPTION_AMOUNTS: 'Выкупные суммы',
  GUARANTEED_PAYMENTS: 'Гарант-выплаты',
  THE_AMOUNT_OF_THE_GUARANTEE_PAYMENT_PERCENT: 'Размер гарант-выплаты (в % от премии)',
  RANSOM_ACTION_YEAR: 'Год действия',
  RANSOM_DATE_BEGIN: 'Дата начала',
  RANSOM_DATE_END: 'Дата окончания',
  RANSOM_SURVIVAL_PERCENTAGE: 'ВС в % от СС по Дожитию',
  RANSOM_SIZE_IN_CURRENCY: 'Размер ВС в валюте договора',
  RECOVERY_ACCESS_EMAIL_SUCCESS: 'Ваша заявка на восстановление пароля передана в службу тех. поддержки, ожидайте ответа на ваш e-mail.',
  GROWTH_CAP: 'Ограничение роста актива',
  LAST_AVAILABLE_REPORT_DATE: 'Последняя дата, на \nкоторую доступен отчет',
  SCANS_UPLOADED_BY_CLIENT: 'Сканы паспорта загружаются клиентом',
  CONFIRM_PHONE: 'Подтвердить номер телефона',
  COMPLETE_REGISTRATION: 'Завершить оформление',
  REQUEST_CONFIRMATION_AGAIN: (t) => `Повторно запросить подтверждение можно через ${t} секунд`,
  SEND_NEW_LINK_CONFIRM: 'Если вы поменяете значение параметра, то клиенту надо будет отправить новую ссылку для подтверждения номера телефона',
  CHANGE: 'Поменять',
  SIGNING_METHOD: 'Способ подписания',
  IP_ADDRESSES_LIST: 'Список IP-адресов',
  CLIENT_TEST_SUCCESS_TITLE: 'Тестирование успешно!',
  CLIENT_TEST_SUCCESS_MESSAGE: 'Уведомляем Вас о положительной оценке результатов Вашего тестирования. Вы можете перейти к оформлению продуктов СЖ.',
  CLIENT_TEST_UNSUCCESSFUL_MESSAGE_TITLE: 'Тестирование неуспешно!',
  CLIENT_TEST_UNSUCCESSFUL_MESSAGE_TEXT: 'Уведомляем Вас об отрицательной оценке результата Вашего тестирования. Вы можете пройти тестирование заново через 3 рабочих дня.',
  SOMETHING_WRONG_TITLE: 'Что-то пошло не так!',
  CLIENT_TEST_NEW_LINK_MESSAGE: 'Пройдите по новой ссылке тестирования',
  CLIENT_TEST_CONTACT_FORM_TITLE: 'Введите ФИО и номер телефона Страхователя',
  SEND_SMS: 'Отправить СМС',
  SEND_SMS_AGAIN: 'Отправить повторно',
  CLIENT_TEST_RESEND_LINK_CONFIRMATION_MESSAGE: 'Данное действие приведет к аннулированию ранее направленных ссылок и необходимости проходить тестирование заново. Вы уверены, что хотите аннулировать текущую ссылку?',
  GO_TO_CHECKOUT: 'Перейти к оформлению',
  CLIENT_TEST_SUCCESS_RECORD_EXISTS_MESSAGE: 'Запись об успешном тестировании Клиента уже существует.',
  COMPLETE: 'Завершить',
  CLIENT_TEST_TITLE: 'Выберите один вариант ответа на поставленный вопрос.',
  CLIENT_TEST_UNABLE_TO_PROCESS_MESSAGE: (nextDate) => `Оформление невозможно. Следующая дата тестирования Клиента ${nextDate}`,
};

export default LANG_DICTIONARY;
