import React from 'react';
import PropTypes from 'prop-types';
import { block } from 'bem-cn';
import { PopupContainer } from 'components';
import './styles.scss';

const b = block('warning-message-popup');

const propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  link: PropTypes.object,
  isCloseButton: PropTypes.bool,
};

const defaultProps = {
  className: '',
  link: null,
  isCloseButton: true,
};

const WarningMessagePopup = ({
  title,
  message,
  onClose,
  className,
  link,
  isCloseButton,
}) => (
  <PopupContainer
    isOpen={Boolean(message)}
    close={onClose}
    className={className}
    isCloseButton={isCloseButton}
  >
    <div className={b()}>
      <p className={b('title')}>{title}</p>
      <p className={b('message')}>{message}</p>
      {link && (
        <a className={b('link')} href={`${link.addr.includes('@') ? 'mailto:' : ''} ${link.addr}`}>{link.text}</a>
      )}
    </div>
  </PopupContainer>
);

WarningMessagePopup.propTypes = propTypes;
WarningMessagePopup.defaultProps = defaultProps;
export default WarningMessagePopup;
