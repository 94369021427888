import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';
import InputMask from 'react-input-mask';
import { Form, Icon } from 'semantic-ui-react';
import { InnSnilsValidation } from 'components';
import { withFormikProps } from 'HOC';
import { getFormInputClass, checkRerender, onlyNumbers } from 'helpers';
import CurrencyInput from 'react-currency-input';
import Scroll from 'react-scroll';
import './styles.scss';

const defaultProps = {
  value: '',
  error: '',
  touched: false,
  name: null,
  propertyName: null,
  label: null,
  width: '',
  customValidation: null,
  customError: null,
  mask: null,
  validationSuccess: false,
  wrapClass: '',
  required: false,
  placeholder: '',
  onChange: () => null,
  setFieldTouched: () => null,
  isVisableError: false,
  isBarriers: false,
  checkNumbers: () => null,
  precision: 0,
  disabled: false,
  currency: false,
  prefixScroll: '',
  prefixWrapperClass: '',
  positionMessageError: 'left',
  customCurrency: false,
  onEyeIconClick: null,
};

const propTypes = {
  currency: PropTypes.bool,
  isBarriers: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  error: PropTypes.string,
  touched: PropTypes.bool,
  name: PropTypes.string,
  propertyName: PropTypes.string,
  label: PropTypes.string,
  width: PropTypes.string,
  customValidation: PropTypes.func,
  customError: PropTypes.string,
  mask: PropTypes.string,
  validationSuccess: PropTypes.bool,
  wrapClass: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  setFieldTouched: PropTypes.func,
  checkNumbers: PropTypes.func,
  isVisableError: PropTypes.bool,
  precision: PropTypes.number,
  disabled: PropTypes.bool,
  prefixScroll: PropTypes.string,
  prefixWrapperClass: PropTypes.string,
  positionMessageError: PropTypes.string,
  customCurrency: PropTypes.bool,
  onEyeIconClick: PropTypes.func,
};

const b = block('custom-text-input');

class TextInput extends Component {
  shouldComponentUpdate(nextProps) {
    return checkRerender(this.props, nextProps);
  }

  handleChange = (...args) => {
    const {
      name,
      setFieldTouched,
      onChange,
      propertyName,
      checkNumbers,
    } = this.props;
    const inputName = propertyName || name;
    onChange(...args);
    setFieldTouched(inputName, true);

    // TODO need refactoring
    if (inputName === 'curAccount' && onlyNumbers(args[0].target.value).length === 20) {
      checkNumbers(onlyNumbers(args[0].target.value));
    }
  };

  getInput = (inputProps, currency) => {
    const {
      mask,
      isBarriers,
      placeholder,
      disabled,
      customCurrency,
    } = this.props;

    if (mask) {
      return (
        <Form.Input
          children={(
            <InputMask
              autoComplete="off"
              disabled={disabled}
              placeholder={placeholder}
              maskChar={isBarriers ? ' ' : '_'}
              {...inputProps}
            />
          )}
        />
      );
    }

    // TODO need refactoring
    if (customCurrency) {
      return (
        <input
          type="search"
          autoComplete="off"
          disabled={disabled}
          placeholder={placeholder}
          className={b()}
          {...inputProps}
        />
      );
    }

    // TODO need refactoring
    if (currency) {
      return (
        <CurrencyInput
          type="search"
          autoComplete="off"
          placeholder={placeholder}
          precision={0}
          disabled={disabled}
          className={b()}
          thousandSeparator=" "
          {...inputProps}
        />
      );
    }

    return (
      <input
        type="search"
        autoComplete="off"
        disabled={disabled}
        placeholder={placeholder}
        {...inputProps}
      />
    );
  }

  render() {
    const {
      name,
      error,
      touched,
      label,
      width,
      mask,
      validationSuccess,
      customValidation,
      customError,
      wrapClass,
      required,
      placeholder,
      value,
      setFieldTouched,
      propertyName,
      isVisableError,
      isBarriers,
      precision,
      disabled,
      currency,
      checkNumbers,
      prefixScroll,
      prefixWrapperClass,
      positionMessageError,
      customCurrency,
      onEyeIconClick,
      ...rest
    } = this.props;

    // eslint-disable-next-line react/prop-types
    const { type } = this.props;

    const inputProps = {
      value: value || '',
      name,
      mask,
      ...rest,
      onChange: this.handleChange,
      onClick: onEyeIconClick ? (event) => {
        const rect = event.target.getBoundingClientRect();
        const x = event.clientX - rect.left;
        if (x > rect.width - 20 && x < rect.width) onEyeIconClick(event);
      } : null,
      onMouseMove: onEyeIconClick ? (event) => {
        const rect = event.target.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const { style } = event.target;
        if (x > rect.width - 20 && x < rect.width) {
          style.cursor = 'pointer';
        } else {
          style.cursor = 'text';
        }
      } : null,
    };

    return (
      <Scroll.Element name={`scroll${name}${prefixScroll}`} className={prefixWrapperClass}>
        <Form.Field
          className={getFormInputClass({
            error,
            touched,
            width,
            wrapClass,
            isVisableError,
          })}
          data-tip="helperTip"
          data-for={name}
        >
          <div className="fieldWrap">
            { label && <div className="label">{ label }</div> }
            {
            this.getInput(inputProps, currency)
          }
            { validationSuccess && customValidation(value) && (
            <InnSnilsValidation />
            )}
            { required && <Icon name="write" /> }
            { onEyeIconClick &&
            <Icon name={`eye${type === 'password' ? '' : ' slash'}`} />}
          </div>
          { touched && error && <p className={b('error', { [positionMessageError]: true })}>{error}</p> }
        </Form.Field>
      </Scroll.Element>
    );
  }
}

TextInput.defaultProps = defaultProps;
TextInput.propTypes = propTypes;

export default withFormikProps(TextInput);
