import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { PopupContainer, SetNewPasswordForm, WarningMessagePopup } from 'components';
import { Button, Form } from 'semantic-ui-react';
import { TextInput } from 'components/_deprecated';
import { Formik } from 'formik';
import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import './styles.scss';
import { withCustomRouter } from 'HOC';
import { passwordResetVerifyCode, sendRecoveryCode } from 'redux/actions/authActions';

const initialValues = {
  code: '',
};

const b = block('password-recovery-code-form');

const {
  RESET_PASSWORD,
  ENTER_CONFIRMTION_CODE_SENT_TO_EMAIL,
  ENTER_CONFIRMTION_CODE,
  SEND,
  GET_CODE_AGAIN,
  GET_CODE_AGAIN_VIA,
  REQUIRED,
  INVALID_CODE_ERROR,
  INVALID_CODE_ATTEMPTS,
  INVALID_CODE_LENGTH,
  CONTACT_SUPPORT,
} = LANG_DICTIONARY;

const propTypes = {
  isActive: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  PasswordResetVerifyCode: PropTypes.func.isRequired,
  recoveryPassword: PropTypes.object,
  SendRecoveryCode: PropTypes.func.isRequired,
};

const defaultProps = {
  isActive: false,
  className: '',
  recoveryPassword: {},
};

const validationSchema = yup.object().shape({
  code: yup.string().length(6, INVALID_CODE_LENGTH).required(REQUIRED),
});

const PasswordRecoveryCodeForm = ({
  isActive,
  onClose,
  className,
  PasswordResetVerifyCode,
  recoveryPassword,
  SendRecoveryCode,
}) => {
  const getInputProps = (
    { name, placeholder },
    {
      values,
      errors,
      touched,
      handleChange,
      setFieldValue,
      handleBlur,
    },
  ) => ({
    name,
    placeholder,
    values,
    errors,
    touched,
    onChange: handleChange,
    onBlur: (event) => {
      const { value } = event.target;
      setFieldValue(name, value.trim());
      handleBlur(event);
    },
    onKeyDown: (event) => {
      const specialKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Control', 'Meta'];
      if (event.ctrlKey || event.metaKey) {
        specialKeys.push('v');
      }
      const isSpecialKey = specialKeys.includes(event.key);

      if ((event.key < '0' || event.key > '9') && !isSpecialKey) {
        event.preventDefault();
      }
    },
    onPaste: (event) => {
      const pastedData = event.clipboardData.getData('text').replace(/\D/g, '');
      setFieldValue(name, pastedData.slice(0, 6));
    },
  });

  const [timer, setTimer] = useState(0);
  const [interval, setTimerInterval] = useState(null);

  useEffect(() => {
    if (timer > 0 && interval === null) {
      const newInterval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      setTimerInterval(newInterval);
    } else if (timer === 0 && interval) {
      clearInterval(interval);
      setTimerInterval(null);
    }
  }, [timer, interval]);

  const handleSubmit = (values, { setSubmitting }) => {
    PasswordResetVerifyCode({ userId: recoveryPassword.id, recoveryCode: values.code });
    setSubmitting(false);
  };

  const handleSendCodeAgain = () => {
    setTimer(30);
    SendRecoveryCode({ userEmail: recoveryPassword.userEmail });
  };

  return (
    <>
      <PopupContainer
        isOpen={isActive}
        className={className}
        close={onClose}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {(formData) => (
            <Form className={b('form').toString()}>
              <div className={b()}>
                <div className={b('title')}>{ RESET_PASSWORD }</div>
                <p className={b('message')}>{ ENTER_CONFIRMTION_CODE_SENT_TO_EMAIL }</p>
              </div>
              <div className={b('form-container')}>
                <TextInput
                  {...getInputProps({ name: 'code', placeholder: ENTER_CONFIRMTION_CODE }, formData)}
                  maxLength={6}
                />
              </div>
              { recoveryPassword.success === false && (
              <div className={b('error')}>
                { INVALID_CODE_ERROR(recoveryPassword.remainingAttempts) }
              </div>
              )}
              <div className={b('buttons')}>
                <Button
                  type="button"
                  className={b('btn-get-code-again').toString()}
                  disabled={interval}
                  onClick={handleSendCodeAgain}
                >
                  { timer === 0 ? GET_CODE_AGAIN : `${GET_CODE_AGAIN_VIA} (0:${timer < 10 ? `0${timer}` : timer})` }
                </Button>
                <Button
                  type="submit"
                  className={b('btn-send').toString()}
                  onClick={formData.handleSubmit}
                  disabled={formData.isSubmitting || formData.values.code.length !== 6}
                >
                  {SEND}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        <SetNewPasswordForm
          isActive={recoveryPassword.success}
          className={className}
          onClose={onClose}
          isCloseClickOutside
        />
      </PopupContainer>
      {recoveryPassword.remainingAttempts === 0 && (
        <WarningMessagePopup
          title={INVALID_CODE_ATTEMPTS}
          message={CONTACT_SUPPORT}
          onClose={onClose}
          className={b('popup').toString()}
          link={{ addr: 'support@bcslife.ru', text: 'support@bcslife.ru' }}
          isCloseButton={false}
        />
      )}
    </>
  );
};

PasswordRecoveryCodeForm.propTypes = propTypes;
PasswordRecoveryCodeForm.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  recoveryPassword: state.authReducer.recoveryPassword,
});

export default withCustomRouter(connect(
  mapStateToProps, {
    PasswordResetVerifyCode: passwordResetVerifyCode,
    SendRecoveryCode: sendRecoveryCode,
  },
)(PasswordRecoveryCodeForm));
