import { put, takeLatest, debounce } from 'redux-saga/effects';
import {
  apiCall,
  getError,
  filterEmptyProps,
  simpleDate,
} from 'helpers';
import { toastr } from 'react-redux-toastr';
import { API, LANG_DICTIONARY, ROUTES } from 'consts';
import { ITEM_COUNT_ADMIN_TABLE_USERS } from 'config';
import { setPageUsersAdminTable, getUsersForAdminTable, getUserDataForUpdate } from 'redux/rootActions';
import {
  GET_USERS_FOR_TABLE_FAIL,
  GET_USERS_FOR_TABLE_REQUEST,
  GET_USERS_FOR_TABLE_START,
  GET_USERS_FOR_TABLE_SUCCESS,
  SEARCH_BY_USERS_NAME,
  CREATE_USER_FAIL,
  CREATE_USER_REQUEST,
  CREATE_USER_START,
  CREATE_USER_SUCCESS,
  GET_USER_DATA_FAIL,
  GET_USER_DATA_REQUEST,
  GET_USER_DATA_START,
  GET_USER_DATA_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_USER_START,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  LOCK_USER_REQUEST,
  LOCK_USER_SUCCESS,
  LOCK_USER_START,
  LOCK_USER_FAIL,
  UNLOCK_USER_REQUEST,
  UNLOCK_USER_SUCCESS,
  UNLOCK_USER_START,
  UNLOCK_USER_FAIL,
  GET_PARTNERS_FOR_USER_FAIL,
  GET_PARTNERS_FOR_USER_REQUEST,
  GET_PARTNERS_FOR_USER_START,
  GET_PARTNERS_FOR_USER_SUCCESS,
  GET_BRANCHES_FOR_USER_REQUEST,
  GET_BRANCHES_FOR_USER_SUCCESS,
  GET_BRANCHES_FOR_USER_START,
  GET_BRANCHES_FOR_USER_FAIL,
  GET_USERS_ROLES_FOR_SELECT_REQUEST,
  GET_USERS_ROLES_FOR_SELECT_START,
  GET_USERS_ROLES_FOR_SELECT_SUCCESS,
  GET_USERS_ROLES_FOR_SELECT_FAIL,
  GET_LOGIN_FOR_USER_REQUEST,
  GET_LOGIN_FOR_USER_FAIL,
  GET_LOGIN_FOR_USER_START,
  GET_LOGIN_FOR_USER_SUCCESS,
  GET_PASSWORD_FOR_USER_REQUEST,
  GET_PASSWORD_FOR_USER_FAIL,
  GET_PASSWORD_FOR_USER_START,
  GET_PASSWORD_FOR_USER_SUCCESS,
  SEND_USER_CREDENTIALS,
} from '../types';

const {
  USER_CREATED,
  USER_UPDATED,
  USER_LOCKED,
  USER_UNLOCKED,
  SEND_CREDENTIALS_SUCCESS,
  SEND_CREDENTIALS_ERROR,
} = LANG_DICTIONARY;

function* getPassword({ payload }) {
  try {
    const values = payload;
    yield put({ type: GET_PASSWORD_FOR_USER_START });
    const { data } = yield apiCall({
      url: API.GET_PASSWORD_FOR_USER,
      type: 'GET',
    });
    yield put({ type: GET_PASSWORD_FOR_USER_SUCCESS, payload: { data, values } });
  } catch (e) {
    yield put({ type: GET_PASSWORD_FOR_USER_FAIL, error: getError(e) });
  }
}

function* getLogin({ payload }) {
  try {
    const { values } = payload;
    yield put({ type: GET_LOGIN_FOR_USER_START });
    const { data } = yield apiCall({
      url: API.GET_LOGIN_FOR_USER,
      type: 'POST',
      body: payload.loginData,
    });
    yield put({ type: GET_LOGIN_FOR_USER_SUCCESS, payload: { data, values } });
  } catch (e) {
    yield put({ type: GET_LOGIN_FOR_USER_FAIL, error: getError(e) });
  }
}

function* getRoles() {
  try {
    yield put({ type: GET_USERS_ROLES_FOR_SELECT_START });
    const { data } = yield apiCall({
      url: API.GET_USERS_ROLES_FOR_SELECT,
      type: 'GET',
    });
    data.sort((a, b) => {
      if (a.text < b.text) {
        return -1;
      }
      if (a.text > b.text) {
        return 1;
      }

      return 0;
    });
    yield put({ type: GET_USERS_ROLES_FOR_SELECT_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: GET_USERS_ROLES_FOR_SELECT_FAIL, error: getError(e) });
  }
}

function* unlockUser({ payload }) {
  const {
    body, page, params, id,
  } = payload;
  try {
    yield put({ type: UNLOCK_USER_START });
    yield apiCall({
      url: API.UNLOCK_USER,
      type: 'POST',
      body: { userList: body },
    });
    yield put({ type: UNLOCK_USER_SUCCESS });
    if (id) {
      yield put(getUserDataForUpdate(id));
    } else {
      yield put(getUsersForAdminTable(page, params));
    }
    toastr.success('', USER_UNLOCKED);
  } catch (e) {
    yield put({ type: UNLOCK_USER_FAIL, error: getError(e) });
  }
}

function* lockUser({ payload }) {
  const {
    body, page, params, id,
  } = payload;
  try {
    yield put({ type: LOCK_USER_START });
    yield apiCall({
      url: API.LOCK_USER,
      type: 'POST',
      body: { userList: body },
    });
    yield put({ type: LOCK_USER_SUCCESS });
    if (id) {
      yield put(getUserDataForUpdate(id));
    } else {
      yield put(getUsersForAdminTable(page, params));
    }
    toastr.success('', USER_LOCKED);
  } catch (e) {
    yield put({ type: LOCK_USER_FAIL, error: getError(e) });
  }
}

function* createUser({ payload }) {
  try {
    const { body, history } = payload;
    const rebuildBody = filterEmptyProps(body);
    if (rebuildBody.partnerEnum) {
      rebuildBody.partnerEnum = String(rebuildBody.partnerEnum);
    }
    if (rebuildBody.birthDate) {
      rebuildBody.birthDate = simpleDate(rebuildBody.birthDate);
    }
    const newAffiliation = rebuildBody.affiliations.map((item) => ({
      partnerId: item.partnerId,
      branchId: item.branchId,
      roleId: item.roleId,
      isDefault: Boolean(item.isDefault),
      activeRole: Boolean(item.activeRole),
      inheritRole: Boolean(item.inheritRole),
    }));
    rebuildBody.affiliations = newAffiliation;
    yield put({ type: CREATE_USER_START });
    const data = yield apiCall({
      url: API.USERS,
      type: 'POST',
      body: rebuildBody,
    });
    yield put({ type: CREATE_USER_SUCCESS });
    toastr.success('', USER_CREATED);
    const { userId } = data.data;
    history.pushWithoutSave(`${ROUTES.updateUser}/${userId}`);
  } catch (e) {
    yield put({ type: CREATE_USER_FAIL, error: getError(e) });
  }
}

function* updateUser({ payload }) {
  try {
    const { body, id } = payload;
    const rebuildBody = filterEmptyProps(body);
    delete rebuildBody.id;
    if (rebuildBody.partnerEnum) {
      rebuildBody.partnerEnum = String(rebuildBody.partnerEnum);
    }
    if (rebuildBody.birthDate) {
      rebuildBody.birthDate = simpleDate(rebuildBody.birthDate);
    }
    const newAffiliation = rebuildBody.affiliations.map((item) => ({
      partnerId: item.partnerId,
      branchId: item.branchId,
      roleId: item.roleId,
      isDefault: Boolean(item.isDefault),
      activeRole: Boolean(item.activeRole),
      inheritRole: Boolean(item.inheritRole),
    }));
    rebuildBody.affiliations = newAffiliation;
    yield put({ type: UPDATE_USER_START });
    yield apiCall({
      url: `${API.USERS}/id${id}`,
      type: 'PUT',
      body: rebuildBody,
    });
    yield put({ type: UPDATE_USER_SUCCESS });
    toastr.success('', USER_UPDATED);
  } catch (e) {
    yield put({ type: UPDATE_USER_FAIL, error: getError(e) });
  }
}

function* getUsersForTable({ payload, query }) {
  try {
    yield put({ type: GET_USERS_FOR_TABLE_START });
    const { data } = yield apiCall({
      url: API.GET_USERS_FOR_ADMIN_TABLE,
      type: 'GET',
      query: {
        offset: ITEM_COUNT_ADMIN_TABLE_USERS * (payload - 1),
        limit: ITEM_COUNT_ADMIN_TABLE_USERS,
        ...query,
      },
    });
    yield put({ type: GET_USERS_FOR_TABLE_SUCCESS, payload: data });
    if (!data.data.length && payload !== 1) {
      yield put(setPageUsersAdminTable(payload - 1));
      yield getUsersForTable({ payload: payload - 1, query });
    } else {
      yield put(setPageUsersAdminTable(payload));
    }
  } catch (e) {
    yield put({ type: GET_USERS_FOR_TABLE_FAIL, error: getError(e) });
  }
}

function* searchUsersByName({ payload, page }) {
  yield getUsersForTable({ payload: page, query: payload });
}

function* getUserDataForUpdates({ payload }) {
  try {
    yield put({ type: GET_USER_DATA_START });
    const { data } = yield apiCall({
      url: `${API.USERS}/id${payload}`,
      type: 'GET',
    });
    yield put({ type: GET_USER_DATA_SUCCESS, payload: { data } });
  } catch (e) {
    yield put({ type: GET_USER_DATA_FAIL, error: getError(e) });
  }
}

function* getPartners({ payload: { prefix } }) {
  try {
    yield put({ type: GET_PARTNERS_FOR_USER_START });
    const { data } = yield apiCall({
      url: API.GET_PARTNERS_FOR_SELECT,
      type: 'GET',
    });
    yield put({ type: GET_PARTNERS_FOR_USER_SUCCESS, payload: { data, prefix } });
  } catch (e) {
    yield put({ type: GET_PARTNERS_FOR_USER_FAIL, error: getError(e) });
  }
}

function* getBranches({ payload: { prefix, params } }) {
  try {
    yield put({ type: GET_BRANCHES_FOR_USER_START });
    const { data } = yield apiCall({
      url: API.GET_BRANCHES_FOR_SELECT,
      type: 'GET',
      query: params,
    });
    const rebuildData = data.map(({ partnerId, ...rest }) => ({ ...rest, partner: partnerId }));
    yield put({ type: GET_BRANCHES_FOR_USER_SUCCESS, payload: { data: rebuildData, prefix } });
  } catch (e) {
    yield put({ type: GET_BRANCHES_FOR_USER_FAIL, error: getError(e) });
  }
}

function* sendCredentials({ payload }) {
  try {
    yield put({ type: SEND_USER_CREDENTIALS.start });
    yield apiCall({
      url: API.SEND_USER_CREDENTIAL,
      type: 'POST',
      body: payload,
    });
    yield put({ type: SEND_USER_CREDENTIALS.success });
    toastr.success('', SEND_CREDENTIALS_SUCCESS);
  } catch (e) {
    toastr.error('', SEND_CREDENTIALS_ERROR);
    yield put({ type: SEND_USER_CREDENTIALS.fail, error: getError(e) });
  }
}

function* partnersSaga() {
  yield debounce(500, SEARCH_BY_USERS_NAME, searchUsersByName);
  yield takeLatest(UPDATE_USER_REQUEST, updateUser);
  yield takeLatest(GET_USERS_FOR_TABLE_REQUEST, getUsersForTable);
  yield takeLatest(CREATE_USER_REQUEST, createUser);
  yield takeLatest(GET_USER_DATA_REQUEST, getUserDataForUpdates);
  yield takeLatest(LOCK_USER_REQUEST, lockUser);
  yield takeLatest(UNLOCK_USER_REQUEST, unlockUser);
  yield takeLatest(GET_PARTNERS_FOR_USER_REQUEST, getPartners);
  yield takeLatest(GET_BRANCHES_FOR_USER_REQUEST, getBranches);
  yield takeLatest(GET_USERS_ROLES_FOR_SELECT_REQUEST, getRoles);
  yield takeLatest(GET_LOGIN_FOR_USER_REQUEST, getLogin);
  yield takeLatest(GET_PASSWORD_FOR_USER_REQUEST, getPassword);
  yield takeLatest(SEND_USER_CREDENTIALS.request, sendCredentials);
}

export default partnersSaga;
