import React, { useState } from 'react';

import { connect } from 'react-redux';
import { PopupContainer, PasswordRecoveryCodeForm } from 'components';
import { TextInput } from 'components/_deprecated';
import { Button, Form } from 'semantic-ui-react';
import { checkByEmail } from 'redux/actions/authActions';
import { Formik } from 'formik';
import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import './styles.scss';
import { withCustomRouter } from 'HOC';

const initialValues = {
  email: '',
};

const b = block('password-recovery-form');

const {
  PASSWORD_RESET,
  PASSWORD_RESET_INSTRUCTION,
  EMAIL_REGISTRATION_ERROR,
  INVALID_EMAIL_MESSAGE,
  ACCOUNT_BLOCKED_ERROR,
  ENTER_YOUR_EMAIL,
  NO_MORE_THEN_FIFTY,
  SEND_REQUEST,
  REQUIRED,
} = LANG_DICTIONARY;

const propTypes = {
  isActive: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  CheckByEmail: PropTypes.func.isRequired,
  recoveryPassword: PropTypes.object,
};

const defaultProps = {
  isActive: false,
  className: '',
  recoveryPassword: {},
};

const MAX_EMAIL_LENGTH = 50;

const validationSchema = yup.object().shape({
  email: yup.string().email(INVALID_EMAIL_MESSAGE)
    .max(MAX_EMAIL_LENGTH, NO_MORE_THEN_FIFTY)
    .required(REQUIRED),
});

const PasswordRecoveryForm = ({
  isActive,
  onClose,
  className,
  CheckByEmail,
  recoveryPassword,
}) => {
  const getInputProps = (
    { name, placeholder },
    {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      setFieldValue,
    },
  ) => ({
    name,
    placeholder,
    values,
    errors,
    touched,
    onChange: handleChange,
    onBlur: (event) => {
      handleBlur(event);
      const { value } = event.target;
      setFieldValue(name, value.trim());
    },
  });

  const [isShowCodeForm, setIsShowCodeForm] = useState(false);

  const handleSubmit = (values, { setSubmitting }) => {
    CheckByEmail({ userEmail: values.email });
    setIsShowCodeForm(true);
    setSubmitting(false);
  };

  return (
    <>
      <PopupContainer
        isOpen={isActive}
        className={className}
        close={onClose}
        isCloseClickOutside
      >
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {(formData) => (
            <Form className={b('form').toString()}>
              <div className={b()}>
                <div className={b('title')}>{ PASSWORD_RESET }</div>
                <p className={b('message')}>{ PASSWORD_RESET_INSTRUCTION }</p>
              </div>
              <div className={b('form-container')}>
                <TextInput
                  className={b('input')}
                  {...getInputProps({ name: 'email', placeholder: ENTER_YOUR_EMAIL }, formData)}
                />
              </div>
              { recoveryPassword.id === null && (
              <div className={b('error')}>
                { EMAIL_REGISTRATION_ERROR }
                <a href="mailto:support@bcslife.ru">support@bcslife.ru</a>
              </div>
              )}
              { recoveryPassword.isLocked && (
              <div className={b('error')}>
                { ACCOUNT_BLOCKED_ERROR }
                <a href="mailto:support@bcslife.ru">support@bcslife.ru</a>
              </div>
              )}
              <div className={b('controls')}>
                <Button
                  type="submit"
                  className={b('send-button').toString()}
                  disabled={formData.isSubmitting || formData.values.email === ''}
                  onClick={formData.handleSubmit}
                >
                  {SEND_REQUEST}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        <PasswordRecoveryCodeForm
          isActive={isShowCodeForm && Boolean(recoveryPassword.id) && !recoveryPassword.isLocked}
          className={className}
          onClose={() => { setIsShowCodeForm(false); onClose(); }}
          isCloseClickOutside
        />
      </PopupContainer>
    </>
  );
};

PasswordRecoveryForm.propTypes = propTypes;
PasswordRecoveryForm.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  recoveryPassword: state.authReducer.recoveryPassword,
});

export default withCustomRouter(connect(
  mapStateToProps,
  { CheckByEmail: checkByEmail },
)(PasswordRecoveryForm));
