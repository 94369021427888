import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { block } from 'bem-cn';
import { toastr } from 'react-redux-toastr';
import { Select, Icon } from 'semantic-ui-react';
import { LANG_DICTIONARY } from 'consts';
import { CustomCheckBox } from 'components';
import './styles.scss';

const b = block('info-table-for-user');
const propTypes = {
  setUserOption: PropTypes.func,
  affiliation: PropTypes.arrayOf(PropTypes.object),
  values: PropTypes.object,
  partners: PropTypes.arrayOf(PropTypes.object),
  branches: PropTypes.arrayOf(PropTypes.object),
  usersroles: PropTypes.arrayOf(PropTypes.object),
  partnerbranches: PropTypes.arrayOf(PropTypes.object),
  getPartnerBranches: PropTypes.func,
  errorsAffiliations: PropTypes.object,
  getRolesBranches: PropTypes.func,
};
const defaultProps = {
  errorsAffiliations: {},
  setUserOption: () => null,
  affiliation: {},
  values: {},
  partners: {},
  branches: {},
  usersroles: {},
  partnerbranches: {},
  getPartnerBranches: () => null,
  getRolesBranches: () => null,
};
const {
  NOT_CHOOSED,
  CANT_DELETE_ONE_FIELD,
  PARTNER,
  SUBDIVISION,
  ROLE,
  DEFAULT_SUBDIVISION,
  ACTIVE_ROLE,
  INHERIT_ROLE,
  MANAGMENT,
} = LANG_DICTIONARY;

class InfoTableForUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      key: 1,
      prev: 0,
    };
  }

  iterateKey() {
    const { key, prev } = this.state;
    this.setState({
      key: prev + 1 + key,
      prev: key,
    });
  }

  choosePartner = (index) => (e, { value }) => {
    const { setUserOption, affiliation, values } = this.props;
    const newAff = affiliation;
    newAff[index].partnerId = value;
    setUserOption(newAff, values);
    this.getBranchesByPartner(index);
  }

  chooseRole = (index) => (e, { value }) => {
    const { setUserOption, affiliation, values } = this.props;
    const newAff = affiliation;
    newAff[index].roleId = value;
    setUserOption(newAff, values);
  }

  chooseBranch = (index) => (e, { value }) => {
    const { setUserOption, affiliation, values } = this.props;
    const newAff = affiliation;
    newAff[index].branchId = value;
    // eslint-disable-next-line no-restricted-syntax
    for (const key in newAff) {
      if (newAff[key].branchId === newAff[index].branchId) {
        newAff[index].isDefault = newAff[key].isDefault;
        break;
      }
    }
    setUserOption(newAff, values);
    this.getBranchesRoles(index);
  }

  getPlaceholder = (list, id) => {
    const placeholder = NOT_CHOOSED;

    return list && Object.keys(list).reduce((prev, item) => {
      if (list[item].key === id) {
        return list[item].text;
      }

      return prev;
    }, placeholder);
  }

  checkboxForUserInheritRole(index) {
    const { setUserOption, affiliation, values } = this.props;
    const newAff = affiliation;
    newAff[index].inheritRole = !newAff[index].inheritRole;
    setUserOption(newAff, values);
  }

  checkboxForUserActiveRole(index) {
    const { setUserOption, affiliation, values } = this.props;
    const newAff = affiliation;
    newAff[index].activeRole = !newAff[index].activeRole;
    setUserOption(newAff, values);
  }

  checkboxForUserIsDefault(index) {
    const { setUserOption, affiliation, values } = this.props;
    const newAff = affiliation;
    newAff[index].isDefault = !newAff[index].isDefault;
    // eslint-disable-next-line no-restricted-syntax
    for (const key in newAff) {
      if (newAff[key].branchId === newAff[index].branchId) {
        newAff[key].isDefault = newAff[index].isDefault;
      }
    }
    setUserOption(newAff, values);
  }

  getBranchesByPartner(index, isOnClick = false) {
    const { getPartnerBranches, affiliation } = this.props;
    const id = affiliation[index].partnerId;
    getPartnerBranches(id, index, isOnClick);
  }

  getBranchesRoles(index) {
    const {
      getRolesBranches,
      affiliation,
    } = this.props;
    const id = affiliation[index].branchId;
    getRolesBranches(id, index);
  }

  deleteBranch(index) {
    const { affiliation, values, setUserOption } = this.props;
    const newAff = [...affiliation];
    if (newAff.length !== 1) {
      newAff.splice(index, 1);
    } else {
      toastr.warning(CANT_DELETE_ONE_FIELD);
    }
    setUserOption(newAff, values);
  }

  addBranch(index) {
    const { affiliation, values, setUserOption } = this.props;
    const newAff = [...affiliation];
    const { key } = this.state;
    const branchParams = { ...newAff[index] };
    this.iterateKey();
    Object.keys(branchParams).forEach((branch) => {
      if (branch === 'id') {
        branchParams[branch] += key;
      } else {
        branchParams[branch] = null;
      }
    });
    newAff.splice(index + 1, 0, branchParams);
    setUserOption(newAff, values);
  }

  getErrors(id) {
    const { errorsAffiliations } = this.props;

    return Boolean(errorsAffiliations[id]);
  }

  render() {
    const {
      affiliation,
      partners,
      branches,
      usersroles,
      partnerbranches,
      errorsAffiliations,
    } = this.props;

    const content = (index, {
      id,
      branchId,
      partnerId,
      roleId,
      inheritRole,
      activeRole,
      isDefault,
    }) => (
      <div className={b('content', { error: this.getErrors(index) })} key={`${id}roles`}>
        <div className={b('partner')}>
          <Select
            search
            value={partnerId}
            options={partners || []}
            onChange={this.choosePartner(index)}
            placeholder={this.getPlaceholder(partners, partnerId)}
          />
        </div>
        <div className={b('subdivision')}>
          <Select
            search
            onClick={() => this.getBranchesByPartner(index, true)}
            placeholder={this.getPlaceholder(branches, branchId)}
            options={affiliation[index].branchesForPartner || []}
            onChange={this.chooseBranch(index)}
          />
        </div>
        <div className={b('role')}>
          <Select
            onClick={() => this.getBranchesRoles(index)}
            placeholder={this.getPlaceholder(usersroles, roleId)}
            options={affiliation[index].branchRoles || []}
            onChange={this.chooseRole(index)}
          />
        </div>
        <div className={b('check')}>
          <CustomCheckBox
            name="check"
            checked={isDefault}
            onChange={() => this.checkboxForUserIsDefault(index)}
          />
        </div>
        <div className={b('check')}>
          <CustomCheckBox
            name="check"
            checked={activeRole}
            onChange={() => this.checkboxForUserActiveRole(index)}
          />
        </div>
        <div className={b('check')}>
          <CustomCheckBox
            name="check"
            checked={inheritRole}
            onChange={() => this.checkboxForUserInheritRole(index)}
          />
        </div>
        <div className={b('managment')}>
          <div className={b('managment-trash')}>
            <Icon name="trash" className={b('trash').toString()} onClick={() => this.deleteBranch(index)} />
          </div>
          <div className={b('managment-add')}>
            <Icon name="plus circle" className={b('add-circle').toString()} onClick={() => this.addBranch(index, id)} />
          </div>
        </div>
      </div>
    );

    return (
      <div className={b()}>
        <div className={b('header')}>
          <div className={b('partner')}><p>{PARTNER}</p></div>
          <div className={b('subdivision')}><p>{SUBDIVISION}</p></div>
          <div className={b('role')}><p>{ROLE}</p></div>
          <div className={b('child')}><p>{DEFAULT_SUBDIVISION}</p></div>
          <div className={b('child')}><p>{ACTIVE_ROLE}</p></div>
          <div className={b('child')}><p>{INHERIT_ROLE}</p></div>
          <div className={b('child')}><p>{MANAGMENT}</p></div>
        </div>
        { affiliation.map((
          {
            id,
            branchId,
            partnerId,
            roleId,
            inheritRole,
            activeRole,
            isDefault,
          },
          index,
        ) => content(
          index,
          {
            id,
            branchId,
            partnerId,
            roleId,
            inheritRole,
            activeRole,
            isDefault,
            partners,
            usersroles,
            branches,
            partnerbranches,
          },
        ))}
        {Object.values(errorsAffiliations).map((error) => (
          <div className={b('warning-message')}>
            <Icon name="warning sign" />
            {error}
          </div>
        ))}
      </div>
    );
  }
}

InfoTableForUser.propTypes = propTypes;
InfoTableForUser.defaultProps = defaultProps;

export default InfoTableForUser;
