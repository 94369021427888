import React from 'react';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import {
  InputField,
  InfoTableForUser,
  RequiredLabel,
  CustomCheckBox,
  MainBtn,
} from 'components';
import { Icon } from 'semantic-ui-react';
import { Formik } from 'formik';
import { LANG_DICTIONARY } from 'consts';
import { getFormattedDate } from 'helpers';
import schema from './schema';
import './styles.scss';

const {
  ID,
  LOGIN,
  PASSWORD,
  LAST_NAME,
  FIRST_NAME,
  PATRONYMIC,
  BIRTH_DATE_SHORT,
  EMAIL,
  MOBILE_PHONE,
  SNILS,
  LOCKED,
  UUID,
  HASH,
  DATE_OR_TIME_CREATE,
  DATE_OR_TIME_LOCK,
  DATE_OR_TIME_UNLOCK,
  DATE_OR_TIME_START_BAN,
  TEMP_BAN,
  BAN_USER,
  UNBAN_USER,
  PARTNER_U_ID,
  PARTNER_ENUM,
  SHOW_ON_SITE,
  DATE_LAST_LOGIN,
  LAST_IP_ADDRES,
  STATUS,
  ACTIVED,
  REVOKE,
  CREATE,
  SAVE_WORD,
  NOT_CHOOSED,
  IS_EMPLOYER,
  INSERT_PASSWORD_FOR_CHANGE,
  SEND_CREDENTIALS,
} = LANG_DICTIONARY;

const b = block('creating-or-updating-user');

const propTypes = {
  initialValues: PropTypes.object,
  handleSubmitSuccess: PropTypes.func,
  history: PropTypes.object,
  lockUser: PropTypes.func,
  unlockUser: PropTypes.func,
  partners: PropTypes.arrayOf(PropTypes.object),
  branches: PropTypes.arrayOf(PropTypes.object),
  setOption: PropTypes.func,
  getUserData: PropTypes.func,
  usersroles: PropTypes.arrayOf(PropTypes.object),
  setBranchesWithPartner: PropTypes.func,
  partnerbranches: PropTypes.arrayOf(PropTypes.object),
  getLogin: PropTypes.func,
  getPassword: PropTypes.func,
  setWebSiteStatusTrue: PropTypes.func,
  setWebSiteStatusFalse: PropTypes.func,
  setLockedTrue: PropTypes.func,
  setLockedFalse: PropTypes.func,
  errorsAffiliations: PropTypes.object,
  handleChangeValue: PropTypes.func,
  sendUserCredentials: PropTypes.func,
  isCredentialsLoading: PropTypes.bool,
  gurole: PropTypes.array,
};
const defaultProps = {
  errorsAffiliations: {},
  initialValues: {},
  handleSubmitSuccess: () => null,
  history: {},
  lockUser: () => null,
  unlockUser: () => null,
  partners: [],
  branches: [],
  setOption: () => null,
  getUserData: () => null,
  usersroles: [],
  setBranchesWithPartner: () => null,
  partnerbranches: [],
  getLogin: () => null,
  getPassword: () => null,
  setWebSiteStatusTrue: () => null,
  setWebSiteStatusFalse: () => null,
  setLockedTrue: () => null,
  setLockedFalse: () => null,
  handleChangeValue: () => null,
  sendUserCredentials: () => null,
  isCredentialsLoading: false,
  gurole: [],
};

const getDate = (date) => {
  if (date !== null && date !== '' && date !== undefined) {
    return getFormattedDate(date);
  }

  return true;
};

const maskPhone = '+7(999)999-99-99';

const maskSnils = '999-999-999 99';

const CreatingOrUpdatingUser = ({
  initialValues,
  handleSubmitSuccess,
  history,
  lockUser,
  unlockUser,
  partners,
  branches,
  setOption,
  getUserData,
  usersroles,
  setBranchesWithPartner,
  partnerbranches,
  getLogin,
  getPassword,
  setWebSiteStatusTrue,
  setWebSiteStatusFalse,
  setLockedTrue,
  setLockedFalse,
  errorsAffiliations,
  handleChangeValue,
  sendUserCredentials,
  isCredentialsLoading,
  gurole,
}) => (
  <Formik
    initialValues={initialValues}
    onSubmit={handleSubmitSuccess}
    enableReinitialize
    validationSchema={schema(initialValues.id)}
  >
    {({
      values,
      errors,
      touched,
      handleBlur,
      handleSubmit,
    }) => {
      const inputProps = {
        values,
        errors,
        touched,
        onBlur: handleBlur,
      };

      const lockUserById = (e) => {
        e.preventDefault();
        lockUser([initialValues.id], null, null, initialValues.id);
      };

      const unlockUserById = (e) => {
        e.preventDefault();
        unlockUser([initialValues.id], null, null, initialValues.id);
      };

      const ChekboxWebSiteStatus = (val) => {
        val ? setWebSiteStatusFalse(values) : setWebSiteStatusTrue(values);
      };

      const CheckBoxLockUser = (val) => {
        val ? setLockedTrue(values) : setLockedFalse(values);
      };

      const getPartnerBranches = (partnerId, index, isOnClick = false) => {
        const { affiliations } = values;
        const branchesByPartner = [{
          key: null,
          value: null,
          text: NOT_CHOOSED,
        }];
        branchesByPartner.push(...branches.filter((branch) => branch.partner === partnerId));
        affiliations[index].branchesForPartner = branchesByPartner;
        if (!isOnClick) {
          affiliations[index].branchId = null;
        }
        affiliations[index].branchesForPartner.sort((a, d) => a.text.localeCompare(d.text));
        setBranchesWithPartner(affiliations, values);
      };

      const getRolesBranches = (branchId, index) => {
        const { affiliations } = values;
        affiliations[index].branchRoles = usersroles.slice();
        affiliations[index].branchRoles.sort((a, d) => a.text.localeCompare(d.text));
        setBranchesWithPartner(affiliations, values);
        if (affiliations.length > 1 && branchId !== null) {
          const matchingAffiliations =
            affiliations.filter((affiliation, key) => affiliation.branchId
            === branchId && +key !== index);

          if (matchingAffiliations.length) {
            const roleIndex = affiliations[index].branchRoles.findIndex((role) => role.value
            === matchingAffiliations.roleId);

            if (roleIndex !== -1) {
              affiliations[index].branchRoles.splice(roleIndex, 1);
              const newAff = [...affiliations];
              newAff[index].roleId = null;
              setOption(newAff, values);
            }
          }
        }
      };

      const getLoginForUser = (e) => {
        e.preventDefault();
        getLogin(values);
      };

      const getPasswordForUser = (e) => {
        e.preventDefault();
        getPassword(values);
      };

      const sendCredentials = () => {
        const { login, email, password } = values;
        sendUserCredentials({
          id: initialValues.id,
          login,
          email,
          password,
        });
      };

      const isSendCredentialsBtnDisabled = !values.email
        || !values.login
        || !values.password
        || !initialValues.email;

      const isSuperAdmin = gurole.includes('superadmin');

      return (
        <form className={b()}>
          <div className={b('header')}>
            <RequiredLabel />
          </div>
          <div>
            <div className={b('content')}>
              <div className={b('left-info')}>
                <div className={b('login-input')}>
                  <InputField
                    name="login"
                    title={LOGIN}
                    required
                    {...inputProps}
                    onChange={(e) => handleChangeValue('login', e.target.value)}
                  />
                  <button className={b('login-button')} onClick={getLoginForUser} type="button">
                    <div className={b('login-button-icon')}>
                      <Icon name="redo" />
                    </div>
                  </button>
                </div>
                <div className={b('password-input')}>
                  {initialValues.id ? (
                    <InputField
                      name="password"
                      placeholder={INSERT_PASSWORD_FOR_CHANGE}
                      title={PASSWORD}
                      {...inputProps}
                      onChange={(e) => handleChangeValue('password', e.target.value)}
                    />
                  ) : (
                    <InputField
                      name="password"
                      required
                      title={PASSWORD}
                      {...inputProps}
                      onChange={(e) => handleChangeValue('password', e.target.value)}
                    />
                  )}
                  <button className={b('password-button')} onClick={getPasswordForUser} type="button">
                    <div className={b('login-button-icon')}>
                      <Icon name="redo" />
                    </div>
                  </button>
                </div>
                <InputField
                  name="lastName"
                  required
                  title={LAST_NAME}
                  {...inputProps}
                  onChange={(e) => handleChangeValue('lastName', e.target.value)}
                />
                <InputField
                  name="firstName"
                  required
                  title={FIRST_NAME}
                  {...inputProps}
                  onChange={(e) => handleChangeValue('firstName', e.target.value)}
                />
                <InputField
                  name="middleName"
                  title={PATRONYMIC}
                  {...inputProps}
                  onChange={(e) => handleChangeValue('middleName', e.target.value)}
                />
                <InputField
                  name="birthDate"
                  type="date"
                  title={BIRTH_DATE_SHORT}
                  {...inputProps}
                  onChange={(e) => handleChangeValue('birthDate', e.target.value)}
                />
                <InputField
                  name="email"
                  title={EMAIL}
                  {...inputProps}
                  onChange={(e) => handleChangeValue('email', e.target.value)}
                />
                <InputField
                  name="mobilePhone"
                  mask={maskPhone}
                  title={MOBILE_PHONE}
                  {...inputProps}
                  onChange={(e) => handleChangeValue('mobilePhone', e.target.value)}
                />
                <InputField
                  name="snils"
                  title={SNILS}
                  mask={maskSnils}
                  {...inputProps}
                  onChange={(e) => handleChangeValue('snils', e.target.value)}
                />
                <InputField
                  name="partnerUid"
                  title={PARTNER_U_ID}
                  {...inputProps}
                  onChange={(e) => handleChangeValue('partnerUid', e.target.value)}
                />
                <InputField
                  name="partnerEnum"
                  title={PARTNER_ENUM}
                  {...inputProps}
                  onChange={(e) => handleChangeValue('partnerEnum', e.target.value)}
                />
                <div className={b('employee-user')}>
                  <p className={b('locked-status')}>{IS_EMPLOYER}</p>
                  <Icon
                    name="check"
                    bordered
                    className={b('checkbox', { active: values.isEmployee }).toString()}
                    onClick={() => handleChangeValue('isEmployee', !values.isEmployee)}
                  />
                </div>
                <div className={b('status-info')}>
                  <div className={b('status')}>
                    <p className={b('locked-status')}>{LOCKED}</p>
                    <CustomCheckBox
                      name="check"
                      checked={initialValues.locked}
                      onChange={() => CheckBoxLockUser(initialValues.locked)}
                    />
                  </div>
                  <div className={b('status')}>
                    <p className={b('locked-status')}>{SHOW_ON_SITE}</p>
                    <CustomCheckBox
                      name="check"
                      checked={initialValues.webSiteListed}
                      onChange={() => ChekboxWebSiteStatus(initialValues.webSiteListed)}
                    />
                  </div>
                </div>
              </div>
              {
                initialValues.id === null ? ''
                  : (
                    <div className={b('right-info')}>
                      <div className={b('id')}>
                        <div className={b('id-info')}>
                          <p className={b('right-info-text')}>
                            {ID}
                            :
                          </p>
                          {initialValues.id}
                        </div>
                        <div className={b('id-info')}>
                          <p className={b('right-info-text')}>
                            {UUID}
                            :
                          </p>
                          {initialValues.uuid}
                        </div>
                      </div>
                      <div className={b('right-info-block-hash')}>
                        <p className={b('right-info-text')}>
                          {HASH}
                          :
                        </p>
                        <p>
                          {initialValues.hash}
                        </p>
                      </div>
                      <div className={b('right-info-block')}>
                        <p className={b('right-info-text')}>
                          {DATE_OR_TIME_CREATE}
                          :
                        </p>
                        {getDate(initialValues.dateCreate)}
                      </div>
                      <div className={b('right-info-block')}>
                        <p className={b('right-info-text')}>
                          {DATE_OR_TIME_LOCK}
                          :
                        </p>
                        {getDate(initialValues.dateLock)}
                      </div>
                      <div className={b('right-info-block')}>
                        <p className={b('right-info-text')}>
                          {DATE_OR_TIME_UNLOCK}
                          :
                        </p>
                        {getDate(initialValues.dateUnlock)}
                      </div>
                      <div className={b('right-info-block')}>
                        <p className={b('right-info-text')}>
                          {DATE_LAST_LOGIN}
                          :
                        </p>
                        {getDate(initialValues.dateLastlogin)}
                      </div>
                      <div className={b('right-info-block')}>
                        <p className={b('right-info-text')}>
                          {LAST_IP_ADDRES}
                          :
                        </p>
                        {initialValues.lastIp}
                      </div>
                      <div className={b('right-info-block')}>
                        <p className={b('right-info-text')}>
                          {DATE_OR_TIME_START_BAN}
                          :
                        </p>
                        {getDate(initialValues.startBan)}
                      </div>
                      <div className={b('right-info-block')}>
                        <p className={b('right-info-text')}>
                          {TEMP_BAN}
                          :
                        </p>
                        {initialValues.tempBan}
                      </div>
                      <div className={b('right-info-block')}>
                        <button
                          className={b('ban-button')}
                          onClick={values.locked ? unlockUserById : lockUserById}
                          type="button"
                        >
                          {initialValues.locked ? UNBAN_USER : BAN_USER}
                        </button>
                      </div>
                      <div className={b('right-info-block')}>
                        <p className={b('right-info-text')}>
                          {STATUS}
                          :
                          {initialValues.locked
                            ? (
                              <span className={b('locked-status-true')}>
                                {LOCKED}
                              </span>
                            )
                            : (
                              <span className={b('locked-status-false')}>
                                {ACTIVED}
                              </span>
                            )}
                        </p>
                      </div>
                      {isSuperAdmin && (
                        <div className={b('right-info-block')}>
                          <MainBtn
                            onClick={sendCredentials}
                            type="button"
                            disabled={isSendCredentialsBtnDisabled}
                            text={SEND_CREDENTIALS}
                            isLoading={isCredentialsLoading}
                          />
                        </div>
                      )}
                    </div>
                  )
              }
            </div>
          </div>
          <InfoTableForUser
            affiliation={initialValues.affiliations}
            partners={partners}
            branches={branches}
            setUserOption={setOption}
            values={values}
            getUserData={getUserData}
            usersroles={usersroles}
            getPartnerBranches={getPartnerBranches}
            partnerbranches={partnerbranches}
            errorsAffiliations={errorsAffiliations}
            getRolesBranches={getRolesBranches}
          />
          <div className={b('footer')}>
            <button
              className={b('footer__buttons')}
              onClick={() => history.goBack({ cache: true })}
              type="button"
            >
              {REVOKE}
            </button>
            <button
              className={b('footer__buttons')}
              onClick={handleSubmit}
              type="button"
            >
              {initialValues.id === null ? CREATE : SAVE_WORD}
            </button>
          </div>
        </form>
      );
    }}
  </Formik>
);
CreatingOrUpdatingUser.propTypes = propTypes;
CreatingOrUpdatingUser.defaultProps = defaultProps;
export default CreatingOrUpdatingUser;
