import React, { useState } from 'react';

import { connect } from 'react-redux';
import { PopupContainer, WarningMessagePopup } from 'components';
import { TextInput } from 'components/_deprecated';
import { Button, Form } from 'semantic-ui-react';
import { resetChangePassword } from 'redux/actions/authActions';
import { Formik } from 'formik';
import { block } from 'bem-cn';
import { LANG_DICTIONARY, ROUTES } from 'consts';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import './styles.scss';
import { withCustomRouter } from 'HOC';
import zxcvbn from './zxcvbn.js';

const initialValues = {
  password: '',
  confirmPassword: '',
};

const b = block('set-new-password-form');

const {
  NEW_PASSWORD,
  ENTER_NEW_PASSWORD,
  CONFIRM_NEW_PASSWORD,
  PASSWORD_MIN_LENGTH,
  PASSWORD_MUST_CONTAIN_NUMBER,
  PASSWORD_MUST_CONTAIN_LOWERCASE,
  PASSWORD_MUST_CONTAIN_UPPERCASE,
  PASSWORD_MUST_CONTAIN_SPECIAL_CHAR,
  WEAK_PASSWORD_WARNING,
  PASSWORDS_MATCH,
  PASSWORD_CHANGE_SUCCESS,
  SEND,
  GO_TO_LOGIN_FORM,
} = LANG_DICTIONARY;

const propTypes = {
  isActive: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  ResetChangePassword: PropTypes.func.isRequired,
  recoveryPassword: PropTypes.object,
};

const defaultProps = {
  isActive: false,
  className: '',
  recoveryPassword: {},
};

const validationSchema = yup.object().shape({

});

const SetNewPasswordForm = ({
  isActive,
  onClose,
  className,
  recoveryPassword,
  ResetChangePassword,
}) => {
  const [requirements, setRequirements] = useState();
  const [controlTypes, setControlTypes] = useState({ password: 'password', confirmPassword: 'password' });

  const validatePassword = (password, confirmPassword) => {
    const reqs = {
      minLength: password.length >= 8 || confirmPassword.length >= 8,
      hasNumber: /\d/.test(password) || /\d/.test(confirmPassword),
      hasLowercase: /[a-zа-я]/.test(password) || /[a-zа-я]/.test(confirmPassword),
      hasUppercase: /[A-ZА-Я]/.test(password) || /[A-ZА-Я]/.test(confirmPassword),
      hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password) || /[!@#$%^&*(),.?":{}|<>]/.test(confirmPassword),
      passwordsMatch: password.length > 0 && confirmPassword.length > 0 &&
        password === confirmPassword,
      weakPassword: zxcvbn(password).score >= 3,
    };
    setRequirements(reqs);
  };

  const getInputProps = (
    { name, placeholder },
    {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
    },
  ) => ({
    name,
    placeholder,
    values,
    errors,
    touched,
    onChange: handleChange,
    onBlur: (event) => {
      handleBlur(event);
      validatePassword(values.password, values.confirmPassword);
    },
    onKeyUp: () => {
      validatePassword(values.password, values.confirmPassword);
    },
  });

  const handleSubmit = (values, { setSubmitting }) => {
    ResetChangePassword({ userId: recoveryPassword.id, userPassword: values.password });
    setSubmitting(false);
  };

  const handleEyeIconClick = (...args) => {
    switch (args[0].target.name) {
      case 'password':
        setControlTypes({
          password: toggleControlType(controlTypes.password),
          confirmPassword: controlTypes.confirmPassword,
        });
        break;
      case 'confirmPassword':
        setControlTypes({
          confirmPassword: toggleControlType(controlTypes.confirmPassword),
          password: controlTypes.password,
        });
        break;
      default:
        break;
    }
  };
  const toggleControlType = (controlType) => {
    switch (controlType) {
      case 'text':
        return 'password';
      case 'password':
      default:
        return 'text';
    }
  };

  const isOk = requirements && requirements.minLength && requirements.hasNumber &&
    requirements.hasLowercase && requirements.hasUppercase &&
    requirements.hasSpecialChar && requirements.passwordsMatch &&
    requirements.weakPassword;

  return (
    <>
      <PopupContainer
        isOpen={isActive}
        className={className}
        close={onClose}
        isCloseClickOutside
      >
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {(formData) => (
            <Form className={b('form').toString()}>
              <div className={b()}>
                <div className={b('title')}>{ NEW_PASSWORD }</div>
                <p className={b('message')}>{ ENTER_NEW_PASSWORD }</p>
              </div>
              <div className={`${b('form-container')} ${requirements === undefined || isOk ? '' : 'fields error'}`}>
                <TextInput
                  className={b('input')}
                  {...getInputProps({ name: 'password', placeholder: ENTER_NEW_PASSWORD }, formData)}
                  type={controlTypes.password}
                  onEyeIconClick={handleEyeIconClick}
                />
                <TextInput
                  className={b('input')}
                  {...getInputProps({ name: 'confirmPassword', placeholder: CONFIRM_NEW_PASSWORD }, formData)}
                  type={controlTypes.confirmPassword}
                  onEyeIconClick={handleEyeIconClick}
                />
              </div>
              <div className={b('password-requirements')}>
                <p className={requirements && (requirements.minLength ? b('green') : b('red'))}>
                  { PASSWORD_MIN_LENGTH }
                </p>
                <p className={requirements && (requirements.hasNumber ? b('green') : b('red'))}>
                  { PASSWORD_MUST_CONTAIN_NUMBER }
                </p>
                <p className={requirements && (requirements.hasLowercase ? b('green') : b('red'))}>
                  { PASSWORD_MUST_CONTAIN_LOWERCASE }
                </p>
                <p className={requirements && (requirements.hasUppercase ? b('green') : b('red'))}>
                  { PASSWORD_MUST_CONTAIN_UPPERCASE }
                </p>
                <p className={requirements && (requirements.hasSpecialChar ? b('green') : b('red'))}>
                  { PASSWORD_MUST_CONTAIN_SPECIAL_CHAR }
                </p>
                <p className={requirements && (requirements.weakPassword ? b('green') : b('red'))}>
                  { WEAK_PASSWORD_WARNING }
                </p>
                <p className={requirements && (requirements.passwordsMatch ? b('green') : b('red'))}>
                  { PASSWORDS_MATCH }
                </p>
              </div>
              <div className={b('controls')}>
                <Button
                  type="submit"
                  className={b('send-button').toString()}
                  disabled={formData.isSubmitting || !requirements ||
                    !Object.values(requirements).every(Boolean)}
                  onClick={formData.handleSubmit}
                >
                  {SEND}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        {recoveryPassword.RU && (
          <WarningMessagePopup
            message={PASSWORD_CHANGE_SUCCESS}
            onClose={onClose}
            className={className}
            link={{ addr: ROUTES.signIn, text: GO_TO_LOGIN_FORM }}
            isCloseButton={false}
          />
        )}
      </PopupContainer>
    </>
  );
};

SetNewPasswordForm.propTypes = propTypes;
SetNewPasswordForm.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  recoveryPassword: state.authReducer.recoveryPassword,
});

export default withCustomRouter(connect(
  mapStateToProps,
  { ResetChangePassword: resetChangePassword },
)(SetNewPasswordForm));
